export class Company {
  
  public taxId: string;
  public name: string;
  public address: string;
  public vatRegistered: boolean;
  public bankAccount: string;
  public registrationId: string;

  constructor(
    obj?: any 
  ) {
    this.taxId = obj && obj.extractedTaxId || null;
    this.name = obj && obj.name || null;
    this.address = obj && obj.address || null;
    this.vatRegistered = obj && obj.vatRegistered || null;
    this.bankAccount = obj && obj.bankAccount || null;
    this.registrationId = obj && obj.registrationId || null;
  }
  

  getFormatedBankAccount() {

    if (!this.bankAccount) {
      return '';
    }

    return this.bankAccount.substr(0, 4) 
    + ' ' + this.bankAccount.substr(4, 4) 
    + ' ' + this.bankAccount.substr(8, 4) 
    + ' ' + this.bankAccount.substr(12, 4)
    + ' ' + this.bankAccount.substr(16, 3);

  }
}