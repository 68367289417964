<ion-header class="ion-padding header header-modal" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios">
    <ion-title class="header-title ion-text-left">{{ 'SEARCH.WORK_TYPE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding search-modal">
  <!--<ion-searchbar style="border:1px solid black;" #serviceSearchBar (ionChange)="searchFilter$.next($event.target.value)" placeholder="Poišči storitev..." class="search-modal__search" mode="md"></ion-searchbar>-->

  <ion-list lines="none" class="search-modal__list">
      <ion-item style="background-color:white;" *ngFor="let service of services" (click)="onSelected(service)" class="clickable search-modal__list-item search-modal__list-item--main">
        <b>{{ service.name }}</b>
      </ion-item>
  </ion-list>

</ion-content>
<!--
<ion-header>
  <ion-toolbar mode="ios">
    <ion-title >Izberi storitev</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-list>
    <ion-item *ngFor="let service of services" (click)="onSelected(service)">
      <ion-label><span *ngIf="service.parentId" style="padding-left: 25px; padding-right: 10px;">-</span><span *ngIf="searchPreferences.normalizedService?.id === service.id">*</span>{{ service.name }}</ion-label>
    </ion-item>
    <ion-item (click)="onSelected( null )">
      <ion-label>Vse storitve (ne vem kaj izbrati)</ion-label>
    </ion-item>
  </ion-list>
</ion-content>-->