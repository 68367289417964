<ion-grid style="margin-bottom:10px; border-bottom: 1px solid gray;" class="ion-no-padding">
  <ion-row class="ion-no-padding">
    <ion-col size="12">
      <h6 style="margin-bottom:-5px;"><strong>{{caption}}</strong></h6>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding">
    <ion-col size="3">
      <h6 style="font-weight: bold;" class="filters-field-title"><span *ngIf="working">{{ 'SHARED_WORKING_HOURS_RULE.OPEN' | translate }}</span><span *ngIf="!working">{{ 'SHARED_WORKING_HOURS_RULE.CLOSED' | translate }}</span></h6>
      <ion-toggle (ionChange)="onRuleChanged()" [(ngModel)]="working" style="margin-left:2px; margin-right:-12px; margin-top:2px" color="secondary"></ion-toggle>
    </ion-col>
    <ion-col size="9" *ngIf="working && hoursRangeValue.lower && hoursRangeValue.upper">
      <h6 style="font-weight: bold;" class="filters-field-title">{{ 'SHARED_WORKING_HOURS_RULE.FROM' | translate }} {{ hoursRangeValue.lower }}:00 {{ 'SHARED_WORKING_HOURS_RULE.TO' | translate }} {{ hoursRangeValue.upper }}:00</h6>

      <ion-range (ionChange)="onRuleChanged()" class="clickable" #hours dualKnobs="true" style="padding: 0 20px 0 15px;" [(ngModel)]="hoursRangeValue"  min="5" max="24" step="1" snaps="true" class="ion-padding" mode="ios"></ion-range>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- [value]="{ 'lower': hoursRange.fromHour, 'upper': hoursRange.toHour }"-->

<!--ion-grid>
  <ion-row>
    <ion-col>
      Ponedeljek
    </ion-col>
    <ion-col>
      Dela <ion-toggle></ion-toggle>
    </ion-col>
  </ion-row>
  
  <ion-row>
    <ion-col>
      
    </ion-col>
    <ion-col>
      Odprto od {{ hours?.value?.lower }}:00 - {{ hours?.value?.upper }}:00
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12">
      
    </ion-col>
  </ion-row>

<ion-grid-->





<!--ion-item>
  <ion-label>Ponedeljek</ion-label>
    <ion-toggle>Odprto </ion-toggle>
    <ion-label class="ion-padding">{{ hours?.value?.lower }}:00 - {{ hours?.value?.upper }}:00</ion-label>
</ion-item-->


