<app-working-hours-rule [caption]="mondayLang" (onChange) = "onMondayChange($event)" [workingDefault]="true" [lowerDefault]="8" [upperDefault]="19" ></app-working-hours-rule>

<app-working-hours-rule [caption]="tuesdayLang" (onChange) = "onTuesdayChange($event)"  [workingDefault]="true" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>

<app-working-hours-rule [caption]="wednesdayLang" (onChange) = "onWednesdayChange($event)" [workingDefault]="true" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>

<app-working-hours-rule [caption]="thursdayLang" (onChange) = "onThursdayChange($event)" [workingDefault]="true" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>

<app-working-hours-rule [caption]="fridayLang" (onChange) = "onFridayChange($event)" [workingDefault]="true" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>

<app-working-hours-rule [caption]="saturdayLang" (onChange) = "onSaturdayChange($event)" [workingDefault]="true" [lowerDefault]="8" [upperDefault]="12"></app-working-hours-rule>

<app-working-hours-rule [caption]="sundayLang" (onChange) = "onSundayChange($event)" [workingDefault]="false" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>

<app-working-hours-rule [caption]="holidayLang" (onChange) = "onHolidayChange($event)" [workingDefault]="false" [lowerDefault]="8" [upperDefault]="19"></app-working-hours-rule>