import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchAll, tap } from 'rxjs/operators';
import { Shop } from '../models/shop.model';
import { Place } from '../models/search-preferences/place.model';
import { List } from '../models/list.model';

import { PlacesService } from '../services/places.service';
import { NormalizedServicesService } from '../services/normalized-services.service';
import { SearchPreferencesService } from '../services/search-preferences.service';
import * as moment from 'moment';
import 'moment/locale/sl';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ShopSearchService {

  loading = false;

  constructor( 
    private http: HttpClient,
    private placesService: PlacesService,
    private normalizedServicesService: NormalizedServicesService,
    private searchPreferencesService: SearchPreferencesService,
  ) { }

  /*initialValuesFromRouteParams$( params: Observable<ParamMap>, queryParams: Observable<ParamMap> ): Observable<any> {

    //input: params and query params streams
    //output: api urls stream
    const initialValuesStream = combineLatest( params, queryParams, 
      ( m1, m2 ) => { 
        return this.setInitialValues( m1, m2 ); 
      }
    );


    //input: urls
    //output: array of Shop model objects 
    return initialValuesStream.pipe(

      //map( initialValues => console.log(initialValues) )

    )

  }

  private setInitialValues( params: ParamMap, queryParams: ParamMap ): any {
    
    let initialValues: any = {};

    initialValues.sort = 'distance_asc';

    if ( queryParams.has('sort') ) {
      initialValues.sort = queryParams.get('sort');
    }

    initialValues.radius = 10; //@todo Set default values based on api defaults, but they are location

    if ( queryParams.has('radius') ) {
      initialValues.radius = Number(queryParams.get('radius'));
      initialValues.zoom = 11;
    }

    if ( params.has('location') ) {
      initialValues.location = params.get('location');
      if ( 'vse-lokacije' === initialValues.location &&  !queryParams.has('radius') ) {
        initialValues.radius = 160;
        initialValues.zoom = 7;
      }
    }

    if ( params.has('service') ) {
      initialValues.service = params.get('service');
    } else if ( params.has('serviceType') ) {
      initialValues.service = params.get('serviceType');
    }

    if ( queryParams.has('time_from') ) {
      initialValues.timeFrom = queryParams.get('time_from');
    } 

    if ( queryParams.has('time_to') ) {
      initialValues.timeTo = queryParams.get('time_to');
    } 

    if ( queryParams.has('date') ) {
      initialValues.date = queryParams.get('date');
    } 

    return initialValues;

  }*/

  searchFromRouteParams( params: Observable<ParamMap>, queryParams: Observable<ParamMap> ): any {

    console.log('searchFromRouteParams');
    //input: params and query params streams
    //output: api urls stream
    const urlsStream = combineLatest( params, queryParams, 
      ( m1, m2 ) => {
        console.log('inside combine latest');
        this.searchPreferencesService.initSearchPreferences( m1, m2 );
        return this.composeUrl( m1, m2 ); 
      }
    );

    //input: urls
    //output: array of Shop model objects 
    return urlsStream.pipe(
      tap( url => { this.loading = true; } ),
      //distinctUntilChanged(), //no query for same url
      map( url => this.getShopsByUrl( url ) ),
      switchAll(),
      tap( url => { this.loading = false; } ),
    )

  }

  private getShopsByUrl( url ): any {


    const responseToModelArray = ( response ): Shop[] => {

      if ( response._embedded && response._embedded.shop ) {
        
        return ( response._embedded.shop.map(
            shop => new Shop(shop)
          )
        );

      } else {
        return [];
      }

    };

    const responseToList = ( response ): List => {

      let list = new List( { 
        'totalCount': response.totalCount,
        'latitude': response.latitude,
        'longitude': response.longitude,
        'radius': response.radius,
       } );

      if ( response._embedded && response._embedded.shop ) {
        
        list.items = response._embedded.shop.map(
          shop => new Shop(shop)
        )
        
      } 

      return list;

    };

    return this.http.get( url ).pipe(
      map( responseToList )
    );

  }

  //Search by salon name or location name
  searchByKeywords( searchString: string ): Observable<List|null> {

    if ( 0 === searchString.length ) {
      return of(null);
    }

    //@todo refactor
    const responseToList = ( response ): List => {

      let list = new List( { 
        'totalCount': response.totalCount,
        'latitude': response.latitude,
        'longitude': response.longitude,
        'radius': response.radius,
       } );

      if ( response._embedded && response._embedded.shop ) {
        
        list.items = response._embedded.shop.map(
          shop => new Shop(shop)
        )
        
      } 

      return list;

    };


    let query: string[] = [];
    query.push( 'searchString=' + searchString );
    //query.push( 'sortBy=' + sortBy );
    //query.push( 'sortOrder=' + sortOrder );

    query.push( 'pageSize=200' );

    //let url = 'https://api.pricepilot.io/marketplaces/1/shops?' + query.join('&');
    let url = environment.apiUrl + '/marketplaces/1/shops?luda=1&' + query.join('&');

    return this.http.get( url ).pipe(
      map( responseToList )
    );
  }



  private composeUrl( params: ParamMap, queryParams: ParamMap ): string {


    // service filter
    let taxonSlug: string = null;

    if ( params.has('service') ) {
      taxonSlug = params.get('service');
    } else if ( params.has('serviceType') ) {
      taxonSlug = params.get('serviceType');
    } else {
      taxonSlug = 'lepota';
    }

    //LOCATION

    let inputLocationSlug: string = null;
    let inputLocationId: number = null;
    let inputLocationRadius: number = null;
    let inputLocationLatLong: number[] = null;

    let query: string[] = [];

    //location input params and validation
    if ( params.has('location') ) {
      inputLocationSlug = params.get('location');
    }

    if ( queryParams.has('locationId') ) {
      inputLocationId = Number(queryParams.get('locationId'));
    }

    if ( queryParams.has('radius') ) {
      inputLocationRadius = Number(queryParams.get('radius'));
    }


    if ( queryParams.has('latLong') ) {
      inputLocationLatLong = queryParams.get('latLong').split(',').map( coordStr => Number( coordStr ) );
    }

    //priority logic
    if ( inputLocationLatLong ) {
      query.push( 'locationLatLong=' + inputLocationLatLong.join(',') );

    /*} else if ( inputLocationId ) { //commented out until we set the api
      query.push( 'locationId=' + inputLocationId );
    */
    } else if ( inputLocationSlug ) {

      query.push( 'locationId=' + inputLocationSlug );
      
      /*let place = this.placesService.getBySlug( inputLocationSlug );

      if ( place ) {
        query.push( 'locationString=' + place.id );  
      }*/

    }

    if ( inputLocationRadius ) {
      query.push( 'locationRadius=' + inputLocationRadius );
    };

    //DATE
    if ( queryParams.has('date') ) {

      switch( queryParams.get('date') ) {
        
        case 'today':
          query.push( 'dateFrom=' + moment().format('YYYY-MM-DD') );
          query.push( 'dateTo=' + moment().add(1, 'days').format('YYYY-MM-DD') );
          break; 

        case 'tomorrow':
          query.push( 'dateFrom=' + moment().add(1, 'days').format('YYYY-MM-DD') );
          query.push( 'dateTo=' + moment().add(2, 'days').format('YYYY-MM-DD') );
          break; 

        case 'whenever':
          break;

        case 'this-week':
          query.push( 'dateFrom=' + moment().format('YYYY-MM-DD') );
          query.push( 'dateTo=' + moment().add(7, 'days').format('YYYY-MM-DD') );
          break;

        default:
          query.push( 'dateFrom=' + queryParams.get('date') );
          query.push( 'dateTo=' + moment( queryParams.get('date') ).add(1, 'days').format('YYYY-MM-DD') );
          break;

      }

    }


    //DATE FROM
    if ( queryParams.has('free_parking') ) {
      query.push( 'freeParkingOnly=1');
    }

    //DATE FROM
    if ( queryParams.has('date_from') ) {
      query.push( 'dateFrom=' + queryParams.get('date_from') );
    }

    //DATE TO
    if ( queryParams.has('date_to') ) {
      query.push( 'dateTo=' + queryParams.get('date_to') );
    }

    //TIME
    if ( queryParams.has('time_from') ) {
      query.push( 'timeFrom=' + queryParams.get('time_from') );
    }

    if ( queryParams.has('time_to') ) {
      query.push( 'timeTo=' + queryParams.get('time_to') );
    }

    //SORT
    let sortBy: string = null;
    let sortOrder: string = null;

    //Forced
    if ( queryParams.has('sort') ) {
      
      const sortArr = queryParams.get('sort').split('_');
      sortBy = sortArr[0];
      sortOrder = sortArr[1];

    //No sort defined by user -> here you can change api defaults
    } else {
    }

    if ( sortBy && sortOrder ) {
      query.push( 'sortBy=' + sortBy );
      query.push( 'sortOrder=' + sortOrder );
    }

    if ( taxonSlug ) {
      query.push( 'taxonSlug=' + taxonSlug );
    }

    query.push( 'pageSize=100' );

    console.log(query.join('&'));

    //return 'https://api.pricepilot.io/marketplaces/1/shops?' + query.join('&');
    return environment.apiUrl + '/marketplaces/1/shops?luda=1&' + query.join('&');

  }




}
