import { empty as observableEmpty, throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import { take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";

import { PPAuthService } from "../services/auth.service";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PreviousRouteService } from '../services/previous-route.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class UnauthInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector) {}

    addToken(req: HttpRequest<any>, token: string | false ): HttpRequest<any> {

        const noAuthUrls = [ environment.apiUrl + '/refresh-token' ]

        if ( noAuthUrls.includes( req.url ) || false === token ) {

            return req;
        }

        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }})
        //return req.clone({ setHeaders: { Authorization: 'Bearer DUMMY_ACCESS_TOKEN_DUMMY_SUPER_ADMIN' }})
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        
        
        const authService = this.injector.get(PPAuthService);

        if (!window.navigator.onLine) {

            return observableEmpty();
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            //return observableThrowError(new HttpErrorResponse({ error: 'Internet is required.' }));
        } 


        return next.handle(this.addToken(req, authService.getAccessToken())).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next);
                        case 404:
                            return this.handle404Error(req, error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            console.log('10');
            return this.logoutUser();
        }

        return observableThrowError(error);
    }


    handle404Error(req: HttpRequest<any>, error) {

        /*if ( req.headers.get('No-Auth') && 'True' === req.headers.get('No-Auth') ) {
            return observableEmpty();
        }*/

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

        if ( req.headers.get('No-Auth') && 'True' === req.headers.get('No-Auth') ) {
            return observableThrowError('Invalid code');
        }

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(PPAuthService);
            
            return authService.refreshTokens().pipe(
                switchMap((newToken: string) => {
                    console.log('switchMap');
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        return next.handle(this.addToken(req, newToken));
                    }



                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error => {
                    console.log('catchError');
                    console.log(error);
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    console.log('finalize');
                    this.isRefreshingToken = false;
                }),);
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }),);
        }
    }

    logoutUser() {
        // Route to the login page (implementation up to you)
        const authService = this.injector.get(PPAuthService);
        const router = this.injector.get(Router);
        
        authService.logout();

        router.navigateByUrl( this.getRefreshUrl(router.url) );

        return observableEmpty();
    }

    //Ugly hack for reloading the same url. Please rewrite.
    getRefreshUrl(url) {

        if ( url.includes('predogled') || url.includes('narejena') || url.includes('moje-rezervacije') || url.includes('nastavitve') ) {
            return '';
        }

        if ( url.includes('refresh=') ) {
            return url.replace(/refresh=(\d*)/, (match, p1) => { 
                let i = parseInt(p1);
                i++;
                return 'refresh=' + i.toString(); 
            });

        } else if ( url.includes('?') ) {
            return url += '&refresh=1';
        } else {
            return url += '?refresh=1';
        }

    }


}