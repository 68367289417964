export class Shop {
  
  public name: string;
  public providerId: string;
  public slug: string;
  public area: string;
  public avatar: string;
  public gallery: string[];
  public description: string;
  public page: string;
  public distance: string;
  public location: string;
  public street: string;
  public streetNumber: string;
  public latitude: number;
  public longitude: number;
  public rating: number;
  public numOfRatings: string;
  public numOfReviews: string;
  public servicesSample: string;
  public freeParking: string;
  public newOnMarketplace: boolean;
  public exposedOnMarketplace: boolean;
  public specialOfferOnMarketplace: boolean;

  private hardcodedNames = { 
    'slovenska': 'Ljubljanski brivec Slovenska',
    'hotel-union': 'Ljubljanski brivec Hotel Union',
    'trubarjeva': 'YMS Trubarjeva',
    'citypark': 'YMS Citypark',
    'aleja': 'YMS Aleja',
    'ljubljana-mostec': 'Simple Mostec',        
    'frizerski-salon-maribor-europark': 'Estela frizerski salon Maribor',        
    'frizerski-studio-a-ana-rajar-s-p': 'Frizerski studio A',     
    'kozmeticni-salon-chill-room': 'Snip Snap Chill Room',   
    'frizerski-salon-snip-snap-professional': 'Snip Snap Professional',      
    'frizerski-salon-ljubljana-btc': 'Estela frizerski salon BTC',      
    'kozmeticni-salon-ljubljana-hipermarket-mercator': 'Estela kozmetični salon Ljubljana',      
    'frizerski-salon-ljubljana-hipermarket-mercator': 'Estela frizerski salon Ljubljana',    
    'pedimed-pe-ljubljana': 'Pedimed Ljubljana',      
    'pedimed-pe-novo-mesto': 'Pedimed Novo mesto',      
    'viki-s-place': 'Viki\'s Place Trg Republike',
    'viki-s-bar-wolfova': 'Viki\'s Bar Wolfova',
    'dm-studio-kranj-bleiweisova': 'dm Studio Kranj Bleiweisova',
  }  


  constructor(
    obj?: any 
  ) {
    this.name = obj && obj.name || null;
    this.providerId = obj && obj.providerId || null;
    this.slug = obj && obj.itemSlug || null;
    this.area = obj && obj.area || null;
    this.avatar = obj && obj.avatar || null;
    this.gallery = obj && obj.gallery || null;
    this.description = obj && obj.description || null;
    this.page = obj && obj.page || null;
    this.distance = obj && obj.distance || null;
    this.location = obj && obj.location || null;
    this.street = obj && obj.street || null;
    this.streetNumber = obj && obj.streetNumber || null;
    this.latitude = obj && obj.latitude || null;
    this.longitude = obj && obj.longitude || null;
    this.rating = obj && obj.rating || null;
    this.numOfRatings = obj && obj.numOfRatings || null;
    this.numOfReviews = obj && obj.numOfReviews || null;
    this.servicesSample = obj && obj.servicesSample || null;
    this.freeParking = obj && obj.freeParking || null;
    this.newOnMarketplace = obj && obj.newOnMarketplace || null;
    this.exposedOnMarketplace = obj && obj.exposedOnMarketplace || null;
    this.specialOfferOnMarketplace = obj && obj.specialOfferOnMarketplace || null;
  }


  getComposedName() {

    if ( this.slug && this.hardcodedNames[this.slug] ) {
      return this.hardcodedNames[this.slug];
    }

    if ( this.providerId && this.providerId == '112') {
      return 'SIMPLE ' +  this.name;
    
    } else {
      return this.name;
    }

  }
  


}