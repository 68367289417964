import { Pipe, PipeTransform, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ppTranslate'
})
export class TranslatePipe implements PipeTransform {

  constructor( public translate: TranslateService )  {
  }

  transform( defaultText: string, translations: any ): string {

    // console.log('baaaaaaaaaaaaaaaaaaaaaaar'); 
    // console.log(this.translate.currentLang);
    // console.log(translations);

    var languageCode = this.translate.currentLang !== undefined ? this.translate.currentLang.split('-')[0] : undefined;

    //console.log(languageCode);

    if(
        languageCode === undefined
        || translations === null 
        || typeof translations !== 'object' 
        || translations[languageCode] === undefined) {
        return defaultText;
    } else {
        return translations[languageCode];
    }

  }

}