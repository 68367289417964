import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'ppImage'
})
export class ImagePipe implements PipeTransform {

  transform( file: string, ...args: any[] ): any {
    
    if (!file) {
      //return 'https://assets.pricepilot.io/img/employees/blank.jpg';
      return environment.assetsUrl + '/img/employees/blank.jpg';
    }

    //return 'https://docs.api.pricepilot.io/' + file;
    return environment.docsUrl + '/' + file;
  }


}