import { ProviderUser } from './provider-user.model';

export class User {

  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public username: string;
  public phone: string;
  public phoneVerified: boolean;
  public facebookId: string;
  public enteredByAdmin: boolean;
  public providerUsers: ProviderUser[] = [];
  public created: string;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.firstName = obj && ( obj.firstname || obj.firstName ) || null;
    this.lastName = obj && ( obj.lastname || obj.lastName ) || null;
    this.email = obj && obj.contactEmail || null;
    this.username = obj && obj.email || null;
    this.phone = obj && obj.phone || null;
    this.phoneVerified = obj && obj.phoneVerified || null;
    this.facebookId = obj && obj.facebookId || null;
    this.enteredByAdmin = obj && obj.enteredByAdmin || null;


    if ( obj.providerUsers ) {

      for (let pu of obj.providerUsers ) {

       if ( pu instanceof ProviderUser ) {
         this.providerUsers.push( pu );
       } else {
         this.providerUsers.push( new ProviderUser( pu ) );
       }

      }

    }
   
    this.created = obj && obj.created || null;
  }

}