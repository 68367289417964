import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { SearchPreferences } from '../../core/models/search-preferences/search-preferences.model';

import { HoursPeriod } from '../../core/models/search-preferences/hours-period.model';

@Component({
  selector: 'app-hours-pick-modal',
  templateUrl: './hours-pick-modal.component.html',
  styleUrls: ['./hours-pick-modal.component.scss'],
})
export class HoursPickModalComponent implements OnInit {

  @Input() searchPreferences: SearchPreferences;

  constructor(
    private modalCtrl: ModalController,
  ) { 
  }

  ngOnInit() {
  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onConfirmedChange( values ) {
    console.log(values);
    this.modalCtrl.dismiss(
      new HoursPeriod( values.lower, values.upper ), 'picked'
    );

  }

}
