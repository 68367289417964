import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { parse, format, AsYouType } from 'libphonenumber-js';
import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { PPAuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { PreviousRouteService } from './previous-route.service';
import { environment } from '../../../environments/environment';

import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  loading = false;
  toastRef: any;

  constructor(
    private router: Router,
    private previousRouteService: PreviousRouteService,
    public toastController: ToastController,
    private phoneUtils: PhoneUtilsService,
    private http: HttpClient,
    private authService: PPAuthService,
    private translate: TranslateService
  ) { }

  async presentToast(text) {
    this.toastRef = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'primary'
    });
    this.toastRef.present();
  }

  private parser(value) {

    if (!value || !value.substr(1)) {
      return '';
    }

    var formattedInt = this.phoneUtils.getInternational(value, 'SI');

    var formattedIntArray = formattedInt.split(" ");

    var intPrefix = formattedIntArray.shift();

    return intPrefix + ' ' + formattedIntArray.join('');

  }

  addProvider(form, returnUrl) {

    this.loading = true;

    const bankAccountWithoutSpaces = (form.get('companyBankAccount').value).replace(/\s+/g, '');


    const email = this.authService.meSnapshot.email ? this.authService.meSnapshot.email : 'info@luda.app';

    let taxId = form.get('companyTaxId').value;

    if ( taxId.match(/^[0-9]{8,8}$/) ) {
      taxId = 'SI' + taxId;
    } else if (  taxId.match(/^[0-9]{11,11}$/) ) {
      taxId = 'HR' + taxId;
    }

    let body = {

      'brandName': form.get('name').value,
      'description': form.get('description').value,
      'companyName': form.get('companyName').value,
      'companyAddress': form.get('companyAddress').value,
      'companyCountryCode': 'SI',
      'currency': 'EUR',
      'timezone': 'Europe/Ljubljana',
      'taxId': taxId,
      'vatRegistered': form.get('companyVatRegistered').value,
      'registrationId': form.get('companyRegistrationId').value,
      'transactionAccount1': bankAccountWithoutSpaces,
      'email': email,
      'phone1': this.parser(form.get('phone').value),
      'website': form.get('instagram').value,
      'luda': true

    };

    this.http.post(
      environment.apiUrl + '/providers',
      body, { observe: 'response' }
    )

      .subscribe((response: HttpResponse<any>) => {

        this.authService.setMe();
        this.authService.skipProviderGuard = true;

        console.log('provider saved');
        const loc = response.headers.get('location');
        const providerId = loc.substr(loc.lastIndexOf('/') + 1);
        this.loading = false;

        console.log(returnUrl);

        this.router.navigateByUrl(returnUrl ? returnUrl : this.previousRouteService.getPreviousNotAuthUrl()).then(

          result => {
            console.log('navigation complete...')
          },

          error => {
            console.log('navigation error...')
            console.log(error)
          }

        );

        console.log('provider_id: ' + providerId);

      }, error => {
        console.log(error);
        this.loading = false;
        this.presentToast(this.translate.instant('TOAST.SAVE_ERROR'));
        //this.router.navigate( ['/salon', this.activatedRoute.snapshot.params.providerId, this.activatedRoute.snapshot.params.shopSlug, 'rezervacija', 'izberi-termin'] );
      });

  }


  addProvider$(form, returnUrl) {

    this.loading = true;

    const bankAccountWithoutSpaces = (form.get('companyBankAccount').value).replace(/\s+/g, '');


    const email = this.authService.meSnapshot.email ? this.authService.meSnapshot.email : 'info@luda.app';

    let taxId = form.get('companyTaxId').value;

    if ( taxId.match(/^[0-9]{8,8}$/) ) {
      taxId = 'SI' + taxId;
    } else if (  taxId.match(/^[0-9]{11,11}$/) ) {
      taxId = 'HR' + taxId;
    }

    let body = {

      'brandName': form.get('name').value,
      'description': form.get('description').value,
      'companyName': form.get('companyName').value,
      'companyAddress': form.get('companyAddress').value,
      'companyCountryCode': 'SI',
      'currency': 'EUR',
      'timezone': 'Europe/Ljubljana',
      'taxId': taxId,
      'vatRegistered': form.get('companyVatRegistered').value,
      'registrationId': form.get('companyRegistrationId').value,
      'transactionAccount1': bankAccountWithoutSpaces,
      'email': email,
      'phone1': this.parser(form.get('phone').value),
      'website': form.get('instagram').value,
      'luda': true

    };

    return this.http.post(
      environment.apiUrl + '/providers',
      body, { observe: 'response' }
    ).pipe(
      switchMap(result => {

        return this.authService.getMe$().pipe(
          tap(user => {
            this.authService.me = user;
            this.authService.addMe2(user);
          })
        );

      })

    );

  }


}
