import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HoursPeriod } from '../../../core/models/search-preferences/hours-period.model';

@Component({
  selector: 'app-working-hours-rule',
  templateUrl: './working-hours-rule.component.html',
  styleUrls: ['./working-hours-rule.component.scss'],
})
export class WorkingHoursRuleComponent implements OnInit {

  
  @Input() caption: string;

  @Input() workingDefault: boolean = false;
  @Input() lowerDefault: number = 8;
  @Input() upperDefault: number = 19;

  working: boolean = true;
  hoursRangeValue =  { lower: 8, upper: 20 };

  @Output() onChange = new EventEmitter<object>();

  constructor() { }

  ngOnInit() {

    this.working = this.workingDefault;
    this.hoursRangeValue =  { lower: this.lowerDefault, upper: this.upperDefault };

    //console.log(this.hoursRange);
  }

  onRuleChanged() {

    if ( !this.working || !this.hoursRangeValue.lower || !this.hoursRangeValue.upper  ) {
      this.onChange.emit(null);
    } else {
      this.onChange.emit( new HoursPeriod( this.hoursRangeValue.lower, this.hoursRangeValue.upper ) );

    }

  }

  

}
