<ion-header class="ion-padding header header-modal" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios">
    <ion-title class="header-title ion-text-left">{{ 'COLLABORATION.TYPE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
         
    <ion-list>
      <ion-item (click)="collaborationTypePicked('team')">
        <ion-avatar slot="start" style="">
          <ion-img src="/assets/images/team.jpeg"></ion-img>
        </ion-avatar>
        <ion-label>
          <h2>{{ 'COLLABORATION.TEAM' | translate }}</h2>
          <p>{{ 'COLLABORATION.TEAM_1' | translate }}</p>
          <p>{{ 'COLLABORATION.TEAM_2' | translate }}</p>
          <p>{{ 'COLLABORATION.TEAM_3' | translate }}</p>
          <p>{{ 'COLLABORATION.TEAM_4' | translate }}</p>
        </ion-label>
      </ion-item>
      <ion-item (click)="collaborationTypePicked('independent')">
        <ion-avatar slot="start">
          <ion-img src="/assets/images/independent.jpg"></ion-img>
        </ion-avatar>
        <ion-label>
          <h2>{{ 'COLLABORATION.GUEST' | translate }}</h2>
          <p>{{ 'COLLABORATION.GUEST_1' | translate }}</p>
          <p>{{ 'COLLABORATION.GUEST_2' | translate }}</p>
          <p>{{ 'COLLABORATION.GUEST_3' | translate }}</p>
        </ion-label>
      </ion-item>
      
    </ion-list>

   
</ion-content>