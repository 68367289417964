<ion-buttons>
  
  <!--<ion-button>
    <ion-icon class="profile"></ion-icon>
  </ion-button>-->

  <ion-menu-button autoHide="false" [style.--color]="href === '/' ? 'white' : 'black'" ></ion-menu-button>

  <!--<ion-button (click)="logout($event)">Odjava</ion-button>-->

</ion-buttons>