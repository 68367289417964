export class Place {

  public slug: string;
  public name: string;
  public area: number;
  public population: number;
  public latitude: number;
  public longitude: number;
  public zip: string;

  constructor(
    obj?: any 
  ) {
    this.slug = obj && obj.slug || null;
    this.name = obj && obj.name || null;
    this.area = obj && obj.area || null;
    this.population = obj && obj.population || null;
    this.latitude = obj && obj.latitude || null;
    this.longitude = obj && obj.longitude || null;
    this.zip = obj && obj.zip || null;
  }

}