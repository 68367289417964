import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, Subscription, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take, pairwise, startWith} from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastController } from '@ionic/angular';

import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { ProviderService } from  '../../core/services/provider.service';
import { WorkerOnboardingService } from  '../../core/services/worker-onboarding.service';

import { createHrOrSiTaxNumberValidator } from '../../core/validators/hr-or-si-tax-number.validator';

import { Company } from '../../core/models/company.model';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent implements OnInit {

  form: FormGroup;
  companyName: FormControl;
  companyAddress: FormControl;
  companyTaxId: FormControl;
  companyVatRegistered: FormControl;
  companyRegistrationId: FormControl;
  companyBankAccount: FormControl;

  companyNameFirstBlur = false;
  companyNameFocus = false;

  companyAddressFirstBlur = false;
  companyAddressFocus = false;

  companyTaxIdFirstBlur = false;
  companyTaxIdFocus = false;

  companyVatRegisteredFirstBlur = false;
  companyVatRegisteredFocus = false;

  companyRegistrationIdFirstBlur = false;
  companyRegistrationIdFocus = false;

  companyBankAccountFirstBlur = false;
  companyBankAccountFocus = false;

  tryToSubmit = false;

  loading$: Observable<boolean>;

  companyDataEditing = false;

  private taxIdChangesSubscription:Subscription;

  @Output() onSaved = new EventEmitter<object>();

  returnUrl: string;

  toastRef: any;

  constructor( 
    private modalCtrl: ModalController, 
    public toastController: ToastController, 
    private phoneUtils: PhoneUtilsService,  
    private http: HttpClient, 
    private providerService: ProviderService, 
    private route: ActivatedRoute,
    private workerOnboardingService: WorkerOnboardingService,
    private router: Router

  ) { }


  async presentToast( text ) {
    this.toastRef = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'primary'
    });
    this.toastRef.present();
  }

  onFormSubmit() {

    console.log('Submit....');
    console.log(this.form);
    

    this.tryToSubmit = true;

    if ( !this.form.valid ) {
      return false;
    }

    this.workerOnboardingService.companyTaxId = this.companyTaxId.value;
    this.workerOnboardingService.companyName = this.companyName.value;
    this.workerOnboardingService.companyAddress = this.companyAddress.value;
    this.workerOnboardingService.companyVatRegistered = this.companyVatRegistered.value;
    this.workerOnboardingService.companyRegistrationId = this.companyRegistrationId.value;
    this.workerOnboardingService.companyBankAccount = this.companyBankAccount.value;


    if ( '/nov-salon/podjetje' === this.router.url ) {
      this.router.navigate( ['/nov-salon', 'pogodba'] ); //redirect to login
    } else {
      this.router.navigate( ['/nov-stilist', 'pogodba'] ); //redirect to login
    }
    
    return false;

  }

  
  ngOnInit() {

    this.route.queryParams
      .subscribe(params => this.returnUrl = params['return']);

    this.companyName = new FormControl('', [ 
      Validators.required
    ]);

    this.companyAddress = new FormControl('', [ 
      Validators.required
    ]);

    this.companyTaxId = new FormControl(this.workerOnboardingService.companyTaxId, [ 
      Validators.required,
      Validators.pattern( ' *(SI|HR)? *[0-9]{8,11} *'),
      //Validators.pattern( ' *(SI)? *[0-9]{8,8} *'),
      createHrOrSiTaxNumberValidator()
      //createSiTaxNumberValidator()
    ]);

    this.companyVatRegistered = new FormControl(false, [ 
    ]);

    this.companyRegistrationId = new FormControl('', [ 
      Validators.required,
      Validators.pattern( ' *[0-9]{8,11} *')
    ]);

    this.companyBankAccount = new FormControl('', [ 
      Validators.required,
      //Validators.pattern( ' *SI56 ?[0-9]{4,4} *[0-9]{4,4} *[0-9]{4,4} *[0-9]{3,3} *')
      Validators.pattern( ' *[A-Z]{2,2}[ 0-9]{10,30} *')
    ]);


    this.form = new FormGroup({
      companyName: this.companyName,
      companyAddress: this.companyAddress,
      companyTaxId: this.companyTaxId,
      companyVatRegistered: this.companyVatRegistered,
      companyRegistrationId: this.companyRegistrationId,
      companyBankAccount: this.companyBankAccount,
    });


    this.taxIdChangesSubscription = this.form.get('companyTaxId').valueChanges.pipe( startWith(0), pairwise() ).subscribe( ([prev, next]: [any, any]) => {
      
       console.log('change: ' + prev + ' vs. ' + next);

       if ( prev && next && prev.replace(/\D/g, "") == next.replace(/\D/g, "") ) {
         console.log('same tax id');
         return false;
       }

      if ( !this.form.get('companyTaxId').valid  ) {
        this.companyDataEditing = false;
        return false;
      }

      /**if ( prev.match(/\d+/g).map(Number) == next.match(/\d+/g).map(Number) ) {
        console.log('change: ' + prev + ' vs. ' + next);
      }*/

      this.http.get( environment.apiUrl + '/companies?taxId=' + next ).pipe(
        map ( response => new Company( response ) )
      ).subscribe( 

        company => {
          console.log(company);
          this.companyName.setValue(company.name);
          this.companyAddress.setValue(company.address);
          this.companyVatRegistered.setValue(company.vatRegistered);
          //this.companyTaxId.setValue(company.taxId);
          this.companyBankAccount.setValue( company.getFormatedBankAccount() );
          this.companyRegistrationId.setValue(company.registrationId);
          this.companyDataEditing = true;

          if ( !this.companyTaxId.value || this.companyTaxId.value.length < company.taxId.length ) {
            this.companyTaxId.setValue(company.taxId);
          }

        }, 

        err => {
          this.companyName.setValue(null);
          this.companyAddress.setValue(null);
          this.companyVatRegistered.setValue(false);
          this.companyBankAccount.setValue(null);
          this.companyRegistrationId.setValue(null);
          this.companyDataEditing = true;
          this.companyDataEditing = true;
        }

      );


    });


    this.form.controls["companyTaxId"].patchValue(this.workerOnboardingService.companyTaxId);



  }

  ngOnDestroy() {
    this.taxIdChangesSubscription.unsubscribe();
  }

}
