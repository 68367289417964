<ion-header class="ion-padding header header-modal " mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios" >
    <ion-title class="header-title ion-text-left">{{ 'LANDING.SEARCH_NAME' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>



<ion-content class="ion-padding search-modal">

  <ion-searchbar #searchBar style="border:1px solid black;" (ionChange)="searchFilter$.next($event.target.value)" placeholder="{{ 'LANDING.SEARCH_NAME_STRING' | translate }}" class="search-modal__search" mode="md"></ion-searchbar>


  <ion-grid *ngIf="loading"> 
    <ion-row class="ion-align-items-center">
      <ion-col size="12" class="ion-text-center">
        <ion-spinner name="crescent"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>


  <ion-list  class="ion-no-padding"  *ngIf="shopList$ | async as sl">
    
    <ng-container *ngIf="!loading">

      <ion-item  class="clickable" lines="none"  *ngFor="let shop of sl?.items; let i = index" (click)="onSelected(shop)">
        <ion-avatar slot="start">
          <img src="{{ getAvatarUrl( shop.avatar) }}">
        </ion-avatar>
        <ion-label>
          <h2>{{ shop.getComposedName() }}</h2>
          <p>{{ shop.street }} {{ shop.streetNumber }}, {{ shop.area }}</p>
        </ion-label>
      </ion-item>
   
    </ng-container>

  </ion-list>

</ion-content>