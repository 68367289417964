import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, SubscriptionLike as ISubscription } from 'rxjs';
import { map, tap, concatMap, concat, first, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ProviderUser } from '../models/provider-user.model';
import { PreviousRouteService } from './previous-route.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

enum TokenType {
  ACCESS = "accessToken",
  REFRESH = "refreshToken",
}

@Injectable({
  providedIn: 'root'
})
export class PPAuthService {

  accessToken: string;

  refreshToken: string;

  skipProviderGuard = false;

  me: User;

  //Logged in user behavior subject stream
  private me2: Subject<User> = new BehaviorSubject<User>(null);

  me2$ = this.me2.asObservable();

  addMe2(user: User) {
    this.me2.next(user);
  }

  meSnapshot: User;

  getMeSubscription: ISubscription;
  getMe2Subscription: ISubscription;


  //loading indicators
  private loginByEmailLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  loginByEmailLoading$ = this.loginByEmailLoading.asObservable().pipe(distinctUntilChanged());

  private loginByFacebookLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  loginByFacebookLoading$ = this.loginByFacebookLoading.asObservable().pipe(distinctUntilChanged());

  private registerByEmailLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  registerByEmailLoading$ = this.registerByEmailLoading.asObservable().pipe(distinctUntilChanged());


  constructor(
    private http: HttpClient,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    public toastController: ToastController,
    private translate: TranslateService
  ) {
    //set meSnapshot to simplify update operations
    this.getMe2Subscription = this.me2$.subscribe(user => this.meSnapshot = user);
  }

  isLoggedIn() {
    return !!this.me;
  }

  private getToken(tokenType: TokenType) {

    //check AuthService param
    if (!!this[tokenType]) {
      return this[tokenType];
    }

    //check sessionStorage
    if (!!sessionStorage.getItem(tokenType)) {
      this[tokenType] = sessionStorage.getItem(tokenType); //put the token to class storage for faster access
      return sessionStorage.getItem(tokenType);
    }

    //check localStorage
    if (!!localStorage.getItem(tokenType)) {
      this[tokenType] = localStorage.getItem(tokenType); //put the token to class storage for faster access
      return localStorage.getItem(tokenType);
    }

    return false;

  }

  isLudaProviderUserSet() {

    if (this.meSnapshot && this.meSnapshot.providerUsers && this.meSnapshot.providerUsers.length > 0) {
      return true;
    }

    return false;

  }

  isPosCertSet() { //@todo add check when stored codes are store to database

    if (this.meSnapshot && this.meSnapshot.providerUsers && this.meSnapshot.providerUsers[0] && this.meSnapshot.providerUsers[0].provider) {

      if (this.meSnapshot.providerUsers[0].provider.posCertPassword) {
        return true;
      }

    }

    return false;

  }

  getAccessToken() {
    return this.getToken(TokenType.ACCESS);
  }

  getRefreshToken() {
    return this.getToken(TokenType.REFRESH);
  }

  storeAuthTokens(accessToken, refreshToken, rememberMe = false) {

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    if (rememberMe) {
      localStorage.setItem(TokenType.ACCESS, accessToken);
      localStorage.setItem(TokenType.REFRESH, refreshToken);

    } else {
      sessionStorage.setItem(TokenType.ACCESS, accessToken);
      sessionStorage.setItem(TokenType.REFRESH, refreshToken);
    }

  }


  storeRefreshedTokens(accessToken, refreshToken) {
    this.storeAuthTokens(accessToken, refreshToken, !!localStorage.getItem(TokenType.ACCESS));
  }

  private clearAuthTokens() {

    this.accessToken = null;
    this.refreshToken = null;

    localStorage.removeItem(TokenType.ACCESS);
    localStorage.removeItem(TokenType.REFRESH);

    sessionStorage.removeItem(TokenType.ACCESS);
    sessionStorage.removeItem(TokenType.REFRESH);

  }

  logout() {
    this.clearAuthTokens();
    this.addMe2(null);
    this.me = null;
  }

  getMe$(): Observable<User> {
    return this.http.get(environment.apiUrl + '/me?expand=providerUsers,provider,realludaproviders').pipe(
      first(),
      map(data => new User(data))
    )
  }

  setMe() {
    if (!this.getAccessToken()) {
      return false;
    }

    if (this.getMeSubscription) {
      this.getMeSubscription.unsubscribe();
    }

    this.getMeSubscription = this.getMe$().subscribe(
      data => {
        this.me = data;
        this.addMe2(data);
      }
    )

  }


  setMe$() {

    return new Observable<boolean>(obs => {

      if (!this.getAccessToken()) {
        obs.next(false);
      }

      this.getMe$().pipe(
        tap(user => {
          this.me = user;
          this.addMe2(user);
          obs.next(true);
        })
      );

    });

  }

  sendEmailOnForgottenPassword$(email) {

    return this.http.post(
      environment.apiUrl + '/forgotten-password',
      {
        domain: environment.domain,
        email: email,
        locale: "sl_SI",
        resetPath: environment.apiUrl + "/auth/ponastavitev",
        siteName: environment.siteName
      }
    );

  }


  resetPassword$(password, email, token) {

    return this.http.post(
      environment.apiUrl + '/reset-password',
      {
        password: password,
        email: email,
        token: token,
        domain: environment.domain
      }
    );

  }


  refreshTokens() {

    const refreshToken = this.getRefreshToken();

    return this.http.post(
      environment.apiUrl + '/refresh-token',
      {
        'refreshToken': refreshToken,
      }
    )
      .pipe(
        tap((result: LoginResponse) => this.storeRefreshedTokens(result.access_token, result.refresh_token)),
        map((result: LoginResponse) => result.access_token)
      )

  }


  //@todo napiši lepše -> s pipe-om
  loginByFacebook(token, rememberMe = false, returnUrl = null) {

    this.loginByFacebookLoading.next(true);

    this.http.post(
      environment.apiUrl + '/login',
      {
        'token': token,
        'type': 'facebook',
        'domain': environment.domain,
      }
    )
      .pipe(
        /*tap( ( data: LoginResponse ) => {
          this.storeAuthTokens( data.access_token, data.refresh_token, rememberMe );
        })*/
      ).subscribe(
        (data: LoginResponse) => {

          this.storeAuthTokens(data.access_token, data.refresh_token, rememberMe);

          this.getMe$().subscribe(

            data => {
              this.me = data;
              this.addMe2(data);

              this.loginByFacebookLoading.next(false);

              this.router.navigateByUrl(returnUrl ? returnUrl : this.previousRouteService.getPreviousNotAuthUrl());
            }

          )

        },
        error => {

          this.loginByFacebookLoading.next(false);

          this.presentToast(this.translate.instant('TOAST.UNKNOWN_ERROR'));

        }


      );

  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 10000,
      color: 'primary'
    });
    toast.present();
  }

  //@todo napiši lepše -> s pipe-om
  loginByEmail(email, password, rememberMe = false, returnUrl = null, onRegistration = false,) {


    this.loginByEmailLoading.next(true);

    this.http.post(
      environment.apiUrl + '/login',
      {
        'email': email,
        'password': password,
        'type': 'email',
        'domain': environment.domain,
      }
    )

      .pipe(

        /*tap( ( data: LoginResponse ) => {
          this.storeAuthTokens( data.access_token, data.refresh_token, rememberMe );
          this.setMe();
        })*/

      ).subscribe(

        (data: LoginResponse) => {

          this.storeAuthTokens(data.access_token, data.refresh_token, rememberMe);

          this.getMe$().subscribe(

            data => {
              this.me = data;
              this.addMe2(data);
              this.router.navigateByUrl(returnUrl ? returnUrl : this.previousRouteService.getPreviousNotAuthUrl()).then(
                () => {
                  this.loginByEmailLoading.next(false),
                    this.registerByEmailLoading.next(false)
                },
                () => {
                  this.loginByEmailLoading.next(false)
                  this.registerByEmailLoading.next(false)
                }
              );
            }

          );

        },

        error => {

          this.loginByEmailLoading.next(false);
          this.registerByEmailLoading.next(false);

          if (onRegistration) {
            this.presentToast(this.translate.instant('TOAST.EMAIL_ERROR'));
          } else {
            this.presentToast(this.translate.instant('TOAST.USERNAME_ERROR'));
          }

        }

      );

  }

  registerByEmail(firstName, lastName, email, password, returnUrl) {


    this.registerByEmailLoading.next(true);

    this.http.post(
      environment.apiUrl + '/users',
      {
        'firstname': firstName,
        'lastname': lastName,
        'alternativeEmail': email,
        'plainPassword': password,
        'type': 'email',
        'domain': environment.domain
      }
    )

      .pipe(

        tap(() => {
          this.loginByEmail(email, password, false, returnUrl);
        })

      ).subscribe(
        ok => {
          console.log('ok');
        },
        error => {
          this.loginByEmail(email, password, false, returnUrl, true);
        }

      );

  }



  private patchUser(userId, params) {

    return this.http.patch(
      environment.apiUrl + '/users/' + userId,
      params
    );

  }

  private pathProviderUser(userId, providerUserId, params) {

    return this.http.patch(
      environment.apiUrl + '/users/' + userId + '/providerusers/' + providerUserId,
      params
    );

  }

  updateConsentToggle(apiParam: 'promotionsViaEmail' | 'promotionsViaSms', value: boolean) {

    interface ConsentParams {
      promotionsViaEmail?: boolean;
      promotionsViaSms?: boolean;
    }

    let paramsObj: ConsentParams = {};
    paramsObj[apiParam] = value;

    if (!this.meSnapshot || !this.meSnapshot.id) {
      throw Error('meSnapshot not set.');
    }


    for (let pu of this.meSnapshot.providerUsers) {
      console.log(pu);
      this.pathProviderUser(this.meSnapshot.id, pu.id, paramsObj).subscribe(result => this.setMe());
    }


  }

  updatePhoneNumber(phoneNumber) {

    if (!this.meSnapshot || !this.meSnapshot.id) {
      throw Error('meSnapshot not set.');
    }

    return this.patchUser(this.meSnapshot.id, { 'phone': phoneNumber, 'verified': false })

  }

  sendConfirmationCode() {

    if (!this.meSnapshot || !this.meSnapshot.id) {
      throw Error('meSnapshot not set.');
    }

    return this.http.post(
      environment.apiUrl + '/users/' + this.meSnapshot.id + '/texts',
      { "type": "code" }
    );

  }

  verifyPhoneNumber(code) {

    if (!this.meSnapshot || !this.meSnapshot.id) {
      throw Error('meSnapshot not set.');
    }

    return this.http.get(
      environment.apiUrl + '/users/' + this.meSnapshot.id + '/verify?code=' + code ,
    );

  }

  updateName(params) {

    if (!params.firstname || !params.lastname) {
      throw new Error('Params firstname or lastname are missing. They are required for name update.');
    }

    return this.patchUser(this.meSnapshot.id, params)

  }

  // As of now everything is set to this email, changing is not possible (11.1.2022)
  // updateEmail ( params ) {
  //   console.log(params)
  //   if ( !params.email ) {
  //     throw new Error('Params email is missing. They are required for email update.');
  //   }

  //   return this.patchUser( this.meSnapshot.id, params )
  // }

}