import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  public previousUrlSnapshot: string; 
  
  constructor() { }

  setPreviousUrl(previousUrl: string) {

    this.previousUrl.next(previousUrl);
    this.previousUrlSnapshot = previousUrl;

  }

}
