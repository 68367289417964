import { Injectable } from '@angular/core';
import { NormalizedService } from '../models/normalized-service.model';

import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NormalizedServicesService {

  constructor( private http: HttpClient ) { 
  }


  getBySearchString( searchString ) {
    return this.getNormalizedServices( searchString );
  }

  getBySlug( slug: string ) {
    return this.getNormalizedServices( null, slug ).pipe(
      map ( normalizedServiceArr => normalizedServiceArr[0] )
    );
  }

  getNormalizedServices( searchString, slug = null ) {
    
    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.taxonomyservice ) {
        return response._embedded.taxonomyservice.map(
          taxonomyServiceApiData => new NormalizedService( taxonomyServiceApiData )
        )

      } else {
        return [];
      }

    } 

    const endpoint = '/taxonomyservices';

    let query: string[] = [];

    if ( searchString ) {
      query.push('searchString=' + searchString);
    } else {
      query.push('default=true');
    }

    if ( slug ) {
      query.push('slug=' + slug);
    }

    query.push('pageSize=200');

    const url = environment.apiUrl + endpoint + '?' + query.join('&');

    return this.http.get( url ).pipe(
       map ( response => responseToModel( response ) )
    );

  }

}