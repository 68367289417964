import { NormalizedService } from '../normalized-service.model';
//import { Place } from './place.model';
import { Place } from '../place.model';
import { Radius } from './radius.model';
import { HoursPeriod } from './hours-period.model';
import { DatePeriod } from './date-period.model';
import { Sort } from './sort.model';

export class SearchPreferences {
  
  public normalizedService: NormalizedService;
  
  public place: Place;

  public radius: Radius;

  public datePeriod: DatePeriod;

  public hoursPeriod: HoursPeriod;

  public sort: Sort;

  public url: string;

  public freeParking: boolean = false;

  constructor(
    obj?: any 
  ) {
    this.normalizedService = obj && obj.normalizedService || null;
    this.place = obj && obj.place || null;
    this.radius = obj && obj.radius || null;
    this.datePeriod = obj && obj.datePeriod || null;
    this.hoursPeriod = obj && obj.hoursPeriod || null;
    this.sort = obj && obj.sort || new Sort('distance', 'asc');
    this.freeParking = obj && obj.freeParking || null;
  }
  
  toDisplayString() {

    let displayString = '';

    if ( this.normalizedService ) {
      displayString += this.normalizedService.name + ' ';
    }

    if ( this.place ) {
      displayString += this.place.name + ' ';    
    }

    if ( this.datePeriod ) {
      displayString += this.datePeriod.toDisplayString(false) + ' ';
    }

    if ( this.hoursPeriod ) {
      displayString += this.hoursPeriod.toDisplayString() + ' ';
    }

    if ( this.radius ) {
      displayString += ' v radiju ' + this.radius.maxDistance + 'km ';
    } else {
      displayString += ' ';
    }

    if ( this.freeParking ) {
      displayString += 'z brezplačnim parkiriščem';
    }

    return displayString;

  }

}