import { ExecutionTimeRangeCommand } from './executionTimeRangeCommand.model';

export class ExecutionTimeRange   {
  
  public commands: ExecutionTimeRangeCommand[];
  public timeZone: string;

  constructor(
    obj?: any 
  ) {
    this.timeZone = obj && obj.timeZone || null;


    if ( obj && obj.commands ) {
       this.commands = [];
       for (let command of obj.commands ) {

         if ( command instanceof ExecutionTimeRangeCommand ) {
           this.commands.push( command );
         } else {
           this.commands.push( new ExecutionTimeRangeCommand( command ) );
         }

       }
    }

  }
  
}