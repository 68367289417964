import * as moment from 'moment';

export enum DatePeriodPickedOption {
  Today = "today", 
  Tomorrow = "tomorrow", 
  ThisWeek = "this-week", 
  NextWeek = "next-week", 
  Whenever = "whenever", 
  Custom = "custom"
}

export class DatePeriod {

  constructor(
    public pickedOption: DatePeriodPickedOption,
    public fromMoment: moment.Moment,
    public toMoment: moment.Moment
  ) {}

  toDisplayString( capitalize = true ) {

    let result: string;

    if ( 
      this.fromMoment.isSame( moment().startOf('day') ) &&
      this.toMoment.isSame( moment().add(1, 'days').startOf('day') ) 
    ) {
      return (capitalize?'Danes':'danes') + ' (' + this.fromMoment.locale('sl').format('ddd, D. M') + ')';
    }

    if ( 
      this.fromMoment.isSame( moment().add(1, 'days').startOf('day') ) &&
      this.toMoment.isSame( moment().add(2, 'days').startOf('day') )
    ) {
      return (capitalize?'Jutri':'jutri') + ' (' + this.fromMoment.locale('sl').format('ddd, D. M') + ')';
    }

    if ( 
      this.fromMoment.isSame( 
        moment().startOf('day') 
      ) &&
      this.toMoment.isSame( 
        moment().endOf('week').add(2, 'days').startOf('day')
      ) && 
      this.fromMoment.diff(this.toMoment, 'days') > 3
    ) {
      return (capitalize?'Ta teden':'ta teden');
    }

    if ( this.fromMoment.isSame( 
      moment(this.toMoment).subtract(1, 'days').startOf('day')
    )) {
      return this.fromMoment.locale('sl').format('dddd, D. MMM');
    }

    return this.fromMoment.format('D. MMM') + ' - ' + moment(this.toMoment).subtract(1, 'days').format('D. MMM');

  }



}