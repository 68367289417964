export class HoursPeriod {

  constructor(
    public fromHour: number,
    public toHour: number,
  ) { 
  }

   toDisplayString() {
      return 'od ' + this.fromHour + 'h do ' + this.toHour + 'h';
   }

}