<h1 class="phone-modal__form-title">{{ 'PROVIDER.PRESENTATION_COMPANY' | translate }}</h1>
<p><small>{{ 'PROVIDER.BEFORE_RESERVATION' | translate }}</small></p>

<form novalidate [formGroup]="form" (keyup.enter)="onFormSubmit()" (ngSubmit)="onFormSubmit()" class="form">
 
  <ion-list>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.NAME' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.NAME_INPUT' | translate }}" formControlName="name" (focus)="nameFocus=true;" (blur)="nameFirstBlur=true; nameFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': name.invalid && ( nameFirstBlur || tryToSubmit )
      }"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="name.invalid && ( nameFirstBlur || tryToSubmit ) && !!name.errors?.required">
        {{ 'PROVIDER.BEFORE_RESERVATION' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.MOBILE' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.MOBILE_INPUT' | translate }}" formControlName="phone" (focus)="phoneFocus=true;" (blur)="phoneFirstBlur=true; phoneFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': phone.invalid && ( phoneFirstBlur || tryToSubmit )}" type="text" id="phone" placeholder="{{ 'PROVIDER.INSTAGRAM_EXAMPLE' | translate }} 031 xxx xxx"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="phone.invalid && ( phoneFirstBlur || tryToSubmit ) && !!phone.errors?.required">
      {{ 'PROVIDER.MOBILE_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'PROVIDER.INSTAGRAM_LINK' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.INSTAGRAM_EXAMPLE' | translate }} https://www.instagram.com/foobar99/" formControlName="instagram" (focus)="instagramFocus=true;" (blur)="instagramFirstBlur=true; instagramFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': instagram.invalid && ( instagramFirstBlur || tryToSubmit )}" type="text" id="phone"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'PROVIDER.SCHOOL' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.SCHOOL_INPUT' | translate }}" formControlName="school" (focus)="schoolFocus=true;" (blur)="schoolFirstBlur=true; schoolFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': school.invalid && ( schoolFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'PROVIDER.EXPERIENCE_YEARS' | translate }}</ion-label>
      <ion-select placeholder="{{ 'PROVIDER.SELECT' | translate }}" interface="popover" formControlName="experience" (focus)="experienceFocus=true;" (blur)="experienceFirstBlur=true; experienceFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': experience.invalid && ( experienceFirstBlur || tryToSubmit )}">
        <ion-select-option value="0">{{ 'PROVIDER.EXPERIENCE_0' | translate }}</ion-select-option>
        <ion-select-option value="1">{{ 'PROVIDER.EXPERIENCE_1' | translate }}</ion-select-option>
        <ion-select-option value="2">{{ 'PROVIDER.EXPERIENCE_2' | translate }}</ion-select-option>
        <ion-select-option value="3">{{ 'PROVIDER.EXPERIENCE_3' | translate }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'PROVIDER.SHORT_PRESENTATION' | translate }}</ion-label>
       <ion-textarea rows="2" placeholder="" auto-grow="true" clearOnEdit="true" formControlName="description" (focus)="descriptionFocus=true;" (blur)="descriptionFirstBlur=true; descriptionFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': description.invalid && ( descriptionFirstBlur || tryToSubmit )}"></ion-textarea>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.DDV_NUMBER' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.DDV_NUMBER_INPUT' | translate }}" formControlName="companyTaxId" (focus)="companyTaxIdFocus=true;" (blur)="companyTaxIdFirstBlur=true; companyTaxIdFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyTaxId.invalid && ( companyTaxIdFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyTaxId.invalid && ( companyTaxIdFirstBlur || tryToSubmit ) && !!companyTaxId.errors?.required">
      {{ 'PROVIDER.DDV_NUMBER_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.COMPANY_NAME' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.COMPANY_NAME_INPUT' | translate }}" [disabled]="!companyDataEditing" disabled="true" formControlName="companyName" (focus)="companyNameFocus=true;" (blur)="companyNameFirstBlur=true; companyNameFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyName.invalid && ( companyNameFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyName.invalid && ( companyNameFirstBlur || tryToSubmit ) && !!companyName.errors?.required">
      {{ 'PROVIDER.COMPANY_NAME_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.COMPANY_ADDRESS' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.COMPANY_ADDRESS_INPUT' | translate }}" [disabled]="!companyDataEditing" formControlName="companyAddress" (focus)="companyAddressFocus=true;" (blur)="companyAddressFirstBlur=true; companyAddressFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyAddress.invalid && ( companyAddressFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyAddress.invalid && ( companyAddressFirstBlur || tryToSubmit ) && !!companyAddress.errors?.required">
      {{ 'PROVIDER.COMPANY_ADDRESS_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'PROVIDER.TAXPAYER' | translate }}</ion-label>
      <ion-checkbox name="cb-2" mode="md" [disabled]="!companyDataEditing" [checked]="false" formControlName="companyVatRegistered"></ion-checkbox>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.REG_NUMBER' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.REG_NUMBER_INPUT' | translate }}" [disabled]="!companyDataEditing" formControlName="companyRegistrationId" (focus)="companyRegistrationIdFocus=true;" (blur)="companyRegistrationIdFirstBlur=true; companyRegistrationIdFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyRegistrationId.invalid && ( companyRegistrationIdFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyRegistrationId.invalid && ( companyRegistrationIdFirstBlur || tryToSubmit ) && !!companyRegistrationId.errors?.required">
      {{ 'PROVIDER.REG_NUMBER_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">*{{ 'PROVIDER.TRR_NUMBER' | translate }}</ion-label>
      <ion-input placeholder="{{ 'PROVIDER.TRR_NUMBER_INPUT' | translate }}" [disabled]="!companyDataEditing" formControlName="companyBankAccount" (focus)="companyBankAccountFocus=true;" (blur)="companyBankAccountFirstBlur=true; companyBankAccountFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyBankAccount.invalid && ( companyBankAccountFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyBankAccount.invalid && ( companyBankAccountFirstBlur || tryToSubmit ) && !!companyBankAccount.errors?.required">
      {{ 'PROVIDER.TRR_NUMBER_REQUIRED' | translate }}
    </div>

  </ion-list>

  <!--<ion-button *ngIf="!(loading$ | async)" [disabled]="!form.valid"  type="submit" class="btn--large" expand="full" tabindex="12"> Shrani </ion-button>-->
  <!--<ion-button type="submit" class="btn--large" expand="full" [disabled]="!form.valid" ><ion-spinner name="crescent"></ion-spinner></ion-button>-->

  <ion-button class="btn--large" [disabled]="!companyDataEditing || !form.valid" type="submit" #submitBtn expand="full">{{ 'BUTTON.SAVE' | translate }}</ion-button>

</form>