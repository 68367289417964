import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


/*Primer: davčna številka=1234567X

  1*8+2*7+3*6+4*5+5*4+6*3+7*2=SUMA

  X=11-(Ostanek pri SUMA/11)
  Posebnost: če je X=10 ali 11, poravimo kontrolno št. X=0

*/
export function controlNumberCheckSi( value ) {

  let digits = value.toString().split('');
  let n = digits.map(Number);

  let sum = n[0] * 8 + n[1] * 7 + n[2] * 6 + n[3] * 5 + n[4] * 4 + n[5] * 3 + n[6] * 2;

  let x = 11 - ( sum % 11 );

  if ( 10 === x || 11 === x ) {
    x = 0;
  };

  return n[7] === x;

}






/*ISO7064 (MOD 11,10)
*/
export function controlNumberCheckHr( value ) {


  let modifiedMod10 = function( input ) {

    if ( input % 10 === 0 ) {
      return 10;
    } else {
      return input % 10;
    }
  }


  let digits = value.toString().split('');
  let n = digits.map(Number);

  let c1 = ( modifiedMod10( n[0] + 10 ) * 2 ) % 11;
  let c2 = ( modifiedMod10( n[1] + c1 ) * 2 ) % 11;
  let c3 = ( modifiedMod10( n[2] + c2 ) * 2 ) % 11;
  let c4 = ( modifiedMod10( n[3] + c3 ) * 2 ) % 11;
  let c5 = ( modifiedMod10( n[4] + c4 ) * 2 ) % 11;
  let c6 = ( modifiedMod10( n[5] + c5 ) * 2 ) % 11;
  let c7 = ( modifiedMod10( n[6] + c6 ) * 2 ) % 11;
  let c8 = ( modifiedMod10( n[7] + c7 ) * 2 ) % 11;
  let c9 = ( modifiedMod10( n[8] + c8 ) * 2 ) % 11;
  let c10 = ( modifiedMod10( n[9] + c9 ) * 2 ) % 11;
  let c11 = modifiedMod10 ( n[10] + c10 );

  return c11 === 1;

}

export function createHrOrSiTaxNumberValidator(): ValidatorFn {

    
    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }


      const hasEightNumbersAndFirstIsNotZero = /^ *(SI)?[1-9][0-9]{7,7} *$/.test( value.trim() );
      const siTaxNumberValid = hasEightNumbersAndFirstIsNotZero && controlNumberCheckSi( value.trim().replace(/\D/g,'') );


      const hasElevenNumbers = /^ *(HR)?[0-9]{11,11} *$/.test( value.trim() );
      const hrTaxNumberValid = hasElevenNumbers && controlNumberCheckHr( value.trim().replace(/\D/g,'') );

      if ( !siTaxNumberValid && !hrTaxNumberValid ) {
        return { siOrHrTaxNumber:true }

      } else {
        return null;
      } 

      //return !siTaxNumberValid ? {siTaxNumber:true}: null;

    }


}