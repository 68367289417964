import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { IonicSelectableModule } from 'ionic-selectable';

import { SocialLoginModule, AuthServiceConfig, AuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';

import { AgmCoreModule } from '@agm/core';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';


// the second parameter 'fr' is optional
registerLocaleData(localeSl, 'sl');

let config = new AuthServiceConfig([
  /*{
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("Google-OAuth-Client-Id")
  },*/
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("467582367951288")
  }
]);

export function provideConfig() {
  return config;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicSelectableModule,
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        IonIntlTelInputModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD6Iao_Fuuxgi3SBo3NEsoj99uigkAsC_I'
        }),
        TranslateModule.forRoot({
            // defaultLanguage: 'si',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TranslateModule
    ],
    providers: [
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'sl-SI'
        },
        {
            provide: LOCALE_ID,
            useValue: 'sl-SI'
        },
        AuthService,
        Geolocation
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
