export class ExecutionTimeRangeCommand   {
  
  public validForHolidaysArr: [];
  public weeklyDays: [];
  public dailyIntervals  : [];
  public excepted  : boolean;

  constructor(
    obj?: any 
  ) {
    this.validForHolidaysArr = obj && obj.validForHolidaysArr || [];
    this.weeklyDays = obj && obj.weeklyDays || [];
    this.dailyIntervals = obj && obj.dailyIntervals || [];
    this.excepted = obj && obj.excepted || false;
  
  }

}