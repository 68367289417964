import { Component, OnInit } from '@angular/core';
import { PPAuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  me$: Observable<User>;

  constructor(
    private router: Router,
    private authService: PPAuthService,
    public translate: TranslateService
  ) {

    this.me$ = authService.me2$;

    // set default language (TODO: set language based on browser language?)
    translate.addLangs(['si', 'cro', 'en']);
    translate.setDefaultLang('si');

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/si|cro|en/) ? browserLang : 'si');
    translate.use(translate.defaultLang);
  }

  logout(event: any) {
    console.log('logout');
    this.authService.logout();
    // this.router.navigate(['']);
  }

  ngOnInit() { }

}