export class Provider {
  
  public id: string;
  public avatar: any;
  public slug: string;
  public brandName: string;
  public slogan: string;
  public description: string;
  public companyName: string;
  public companyAddress: string;
  public companyCountryCode: string;
  public timezone: string;
  public currency: string;
  public taxId: string;
  public vatRegistered: string;
  public registrationId: string;
  public transactionAccount1: string;
  public transactionAccount2: string;
  public transactionAccount3: string;
  public posCertPassword: string;
  public posCertReferenceNumber: string;
  public phone1: string;
  public email: string;
  public instagram: string;
  public experience: number;
  public school: string;

  public cadastralMunicipalityId: string;
  public buildingId: string;
  public buildingPartId: string;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.avatar = obj && obj.avatar || null;
    this.slug = obj && obj.slug || null;
    this.brandName = obj && obj.brandName || null;
    this.slogan = obj && obj.slogan || null;
    this.description = obj && obj.description || null;
    this.companyName = obj && obj.companyName || null;
    this.companyAddress = obj && obj.companyAddress || null;
    this.companyCountryCode = obj && obj.companyCountryCode || null;
    this.timezone = obj && obj.timezone || null;
    this.currency = obj && obj.currency || null;
    this.taxId = obj && obj.taxId || null;
    this.vatRegistered = obj && obj.vatRegistered || null;
    this.registrationId = obj && obj.registrationId || null;
    this.transactionAccount1 = obj && obj.transactionAccount1 || null;
    this.transactionAccount2 = obj && obj.transactionAccount2 || null;
    this.transactionAccount3 = obj && obj.transactionAccount3 || null;
    this.posCertPassword = obj && obj.posCertPassword || null;
    this.posCertReferenceNumber = obj && obj.posCertReferenceNumber || null;
    this.phone1 = obj && obj.phone1 || null;
    this.email = obj && obj.email || null;
    this.instagram = obj && obj.instagram || null;
    this.experience = obj && obj.experience || 0;
    this.school = obj && obj.school || null;

    this.cadastralMunicipalityId = obj && obj.cadastralMunicipalityId || null;
    this.buildingId = obj && obj.buildingId || null;
    this.buildingPartId = obj && obj.buildingPartId || null;
  }
  
}