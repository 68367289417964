import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PPAuthService } from '../../../core/services/auth.service';
import { User } from '../../../core/models/user.model';
import { Observable } from 'rxjs';
import { map, tap, filter, first } from 'rxjs/operators';
import { parse, format, AsYouType } from 'libphonenumber-js';
import { ToastController } from '@ionic/angular';
import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
})
export class PhoneFormComponent implements OnInit {

  @Input() bookingDialog: false;
  me$: Observable<User>;

  phoneSubmitDisabled = true;

  phone: string;
  code: string;

  toastRef: any;

  originalPhone: string;

  constructor(
    private authService: PPAuthService,
    public toastController: ToastController,
    private phoneUtils: PhoneUtilsService,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {

    this.me$ = authService.me2$.pipe(
      filter(user => !!user),
      tap(user => this.phone = this.originalPhone = user.phone),
      // tap( user => this.originalPhone = this.phone )
    );

  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  ngOnInit() {
  }

  async presentToast(text) {
    this.toastRef = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'primary'
    });
    this.toastRef.present();
  }

  onPhoneFormSubmit(submitBtn: HTMLButtonElement) {

    this.authService.updatePhoneNumber(this.phone).subscribe(
      result => {
        // console.log(result)
        this.authService.setMe();
        this.presentToast(this.translate.instant('TOAST.PHONE_SAVED'));
      }
    )

  }

  onCodeFormSubmit(submitBtn: HTMLButtonElement) {

    this.authService.verifyPhoneNumber(this.code).pipe(
      first(),
      tap(result => this.authService.setMe())
    ).subscribe(
      null,
      error => this.presentToast(this.translate.instant('TOAST.CODE_INVALID'))
    )

    this.code = '';

  }

  resendCode() {
    this.authService.sendConfirmationCode().pipe(
      first()
    ).subscribe(result => this.presentToast(this.translate.instant('TOAST.SMS_SENT')));
  }

  parser(value) {

    if (!value || !value.substr(1)) {
      return '';
    }

    var formattedInt = this.phoneUtils.getInternational(value, 'SI');

    var formattedIntArray = formattedInt.split(" ");

    var intPrefix = formattedIntArray.shift();

    return intPrefix + ' ' + formattedIntArray.join('');

  }

  formatter(value) {

    if (value) {

      var formatted = this.phoneUtils.getNational(value, 'SI');


      if (formatted && formatted.substr(0, 1) !== '0') {
        return '0' + formatted;
      }

      return formatted;

    } else {

      return '';

    }

    /*if ( value ) { 
      return '0' + value.substr(5);
    } else {
      return ''
    }*/
  }

  isPhoneValid() {
    if (this.phone) {
      let parsed = parse(this.phone, 'SI');
      return !!parsed.phone;
    } else {
      return true;
    }
  }

}
