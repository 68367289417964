import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-collaboration-type-modal',
  templateUrl: './collaboration-type-modal.component.html',
  styleUrls: ['./collaboration-type-modal.component.scss'],
})
export class CollaborationTypeModalComponent implements OnInit {

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {}

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  collaborationTypePicked( value: 'team'|'independent' ) {

    this.modalCtrl.dismiss( value, 'picked' );

  }

}
