<form novalidate [formGroup]="form" (keyup.enter)="onFormSubmit()" (ngSubmit)="onFormSubmit()" class="form">
 
  <ion-list>

    <ion-item class="ion-no-padding" mode="md" style="margin-bottom:5px;">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_TAX_NUMBER' | translate }}</ion-label>
      <ion-input placeholder="{{ 'SHARED_COMPANY_FORM.FIELD_TAX_NUMBER_PHOLDER' | translate }}" formControlName="companyTaxId" (ionFocus)="companyTaxIdFocus=true;" (ionBlur)="companyTaxIdFirstBlur=true; companyTaxIdFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyTaxId.invalid && ( companyTaxIdFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyTaxId.invalid && ( companyTaxIdFirstBlur || tryToSubmit ) && !!companyTaxId.errors?.required">
       {{ 'SHARED_COMPANY_FORM.FIELD_TAX_NUMBER_IS_REQUIRED' | translate }}Davčna številka podjetja je obvezen podatek.
    </div>

    <div class="invalid-feedback" *ngIf="companyTaxId.invalid && ( companyTaxIdFirstBlur || tryToSubmit ) && !companyTaxId.errors?.pattern && !!companyTaxId.errors?.hrOrSiTaxNumber">
      {{ 'SHARED_COMPANY_FORM.FIELD_TAX_NUMBER_INVALID' | translate }}Davčna številka ne obstaja, preveri vnos.
    </div>

    <ion-item class="ion-no-padding" mode="md" style="margin-top:10px;">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_NAME' | translate }}</ion-label>
      <ion-input placeholder="{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_NAME_PHOLDER' | translate }}" [disabled]="!companyDataEditing" disabled="true" formControlName="companyName" (focus)="companyNameFocus=true;" (blur)="companyNameFirstBlur=true; companyNameFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyName.invalid && ( companyNameFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyName.invalid && ( companyNameFirstBlur || tryToSubmit ) && !!companyName.errors?.required">
        {{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_NAME_IS_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_ADDRESS' | translate }}</ion-label>
      <ion-input placeholder="{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_ADDRESS_PHOLDER' | translate }}" [disabled]="!companyDataEditing" formControlName="companyAddress" (focus)="companyAddressFocus=true;" (blur)="companyAddressFirstBlur=true; companyAddressFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyAddress.invalid && ( companyAddressFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyAddress.invalid && ( companyAddressFirstBlur || tryToSubmit ) && !!companyAddress.errors?.required">
        {{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_ADDRESS_IS_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_VAT_REGISTERED' | translate }}</ion-label>
      <ion-checkbox name="cb-2" mode="md" [disabled]="!companyDataEditing" [checked]="false" formControlName="companyVatRegistered"></ion-checkbox>
    </ion-item>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_REGISTRATION_ID' | translate }}</ion-label>
      <ion-input placeholder="{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_REGISTRATION_ID_PHOLDER' | translate }}" [disabled]="!companyDataEditing" formControlName="companyRegistrationId" (focus)="companyRegistrationIdFocus=true;" (blur)="companyRegistrationIdFirstBlur=true; companyRegistrationIdFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyRegistrationId.invalid && ( companyRegistrationIdFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyRegistrationId.invalid && ( companyRegistrationIdFirstBlur || tryToSubmit ) && !!companyRegistrationId.errors?.required">
        {{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_REGISTRATION_ID_IS_REQUIRED' | translate }}
    </div>

    <ion-item class="ion-no-padding" mode="md">
      <ion-label position="stacked" mode="md">{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_BANK_ACCOUNT' | translate }}</ion-label>
      <ion-input placeholder="{{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_BANK_ACCOUNT_PHOLDER' | translate }}" [disabled]="!companyDataEditing" formControlName="companyBankAccount" (focus)="companyBankAccountFocus=true;" (blur)="companyBankAccountFirstBlur=true; companyBankAccountFocus=false;" type="text" tabindex="10" [ngClass]="{
        'is-invalid': companyBankAccount.invalid && ( companyBankAccountFirstBlur || tryToSubmit )}"></ion-input>
    </ion-item>
    <div class="invalid-feedback" *ngIf="companyBankAccount.invalid && ( companyBankAccountFirstBlur || tryToSubmit ) && !!companyBankAccount.errors?.required">
        {{ 'SHARED_COMPANY_FORM.FIELD_COMPANY_BANK_ACCOUNT_IS_REQUIRED' | translate }}
    </div>

  </ion-list>

  <!--<ion-button *ngIf="!(loading$ | async)" [disabled]="!form.valid"  type="submit" class="btn--large" expand="full" tabindex="12">{{ 'BUTTON.SAVE' | translate }}</ion-button>-->
  <!--<ion-button type="submit" class="btn--large" expand="full" [disabled]="!form.valid" ><ion-spinner name="crescent"></ion-spinner></ion-button>-->

  <ion-button class="btn--large" [disabled]="!companyDataEditing || !form.valid" type="submit" #submitBtn expand="full">{{ 'SHARED_COMPANY_FORM.BTN_CONTINUE' | translate }}</ion-button>

</form>