// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: 'http://api.pricepilot.loc/app_dev.php',
//   //apiUrl: 'https://testing.api.pricepilot.si',
//   docsUrl: 'http://docs.api.pricepilot.loc',
//   //docsUrl: 'https://testing.docs.api.pricepilot.si',
//   assetsUrl: 'http://assets.api.pricepilot.loc',
//   //assetsUrl: 'https://testing.assets.api.pricepilot.si',
//   siteId: 133,
//   //siteUrl: 'https://localhost:8100',
//   siteUrl: 'https://localhost:8100',
//   siteName: 'Luda',
//   domain: 'luda.app',
// };

/*
 * Kopiran environment.prod.ts sem (24.1.2021)
 */
export const environment = {
  production: false,
  //apiUrl: 'http://api.pricepilot.loc/app_dev.php',
  apiUrl: 'https://testing.api.pricepilot.si',
  //docsUrl: 'http://docs.api.pricepilot.loc',
  docsUrl: 'https://testing.docs.api.pricepilot.si',
  //assetsUrl: 'http://assets.api.pricepilot.loc',
  assetsUrl: 'https://testing.assets.api.pricepilot.si',
  siteId: 8,
  siteUrl: 'https://luda.app',
  siteName: 'Luda',
  domain: 'luda.app',
  kockaUrl: 'https://main.metakocka.si/rest/eshop/v1/',
  kockaSecretKey: '50711730-583e-4dd7-accc-06fc8549654e',
  kockaCompanyId: '3949',
  ludaTaxonomyProviderId: 9,
  customServiceHref: 'https://testing.api.pricepilot.si/providers/9/services/85'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
