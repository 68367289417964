import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { parse, format, AsYouType } from 'libphonenumber-js';
import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { ProviderService } from '../../core/services/provider.service';
import { Company } from '../../core/models/company.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take, pairwise } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastController } from '@ionic/angular';
import { User } from '../../core/models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.scss'],
})
export class ProviderFormComponent implements OnInit {

  form: FormGroup;
  name: FormControl;
  phone: FormControl;
  //email: FormControl;
  instagram: FormControl;
  school: FormControl;
  experience: FormControl;
  description: FormControl;
  companyName: FormControl;
  companyAddress: FormControl;
  companyTaxId: FormControl;
  companyVatRegistered: FormControl;
  companyRegistrationId: FormControl;
  companyBankAccount: FormControl;

  nameFirstBlur = false;
  nameFocus = false;

  phoneFirstBlur = false;
  phoneFocus = false;

  instagramFirstBlur = false;
  instagramFocus = false;

  schoolFirstBlur = false;
  schoolFocus = false;

  experienceFirstBlur = false;
  experienceFocus = false;

  descriptionFirstBlur = false;
  descriptionFocus = false;

  companyNameFirstBlur = false;
  companyNameFocus = false;

  companyAddressFirstBlur = false;
  companyAddressFocus = false;

  companyTaxIdFirstBlur = false;
  companyTaxIdFocus = false;

  companyVatRegisteredFirstBlur = false;
  companyVatRegisteredFocus = false;

  companyRegistrationIdFirstBlur = false;
  companyRegistrationIdFocus = false;

  companyBankAccountFirstBlur = false;
  companyBankAccountFocus = false;

  tryToSubmit = false;

  loading$: Observable<boolean>;

  companyDataEditing = false;

  private taxIdChangesSubscription: Subscription;
  private phoneChangesSubscription: Subscription;

  @Output() onSaved = new EventEmitter<object>();

  returnUrl: string;

  toastRef: any;

  constructor(
    private modalCtrl: ModalController,
    public toastController: ToastController,
    private phoneUtils: PhoneUtilsService,
    private http: HttpClient,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  async presentToast(text) {
    this.toastRef = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'primary'
    });
    this.toastRef.present();
  }

  onFormSubmit() {

    console.log('Submit....');
    console.log(this.form);


    this.tryToSubmit = true;

    if (!this.form.valid) {
      return false;
    }

    this.providerService.addProvider$(this.form, this.returnUrl).subscribe((response: User) => {

      //this.authService.setMe();
      //this.authService.skipProviderGuard = true;
      //this.presentToast( 'Vašo prijavo so prejeli v salonu. Vaš profil bodo pregledali v salonu in če vas bodo potrdili, boste lahko rezervirali stole.');
      this.onSaved.emit(response);

      console.log('provider saved');

    }, error => {

      console.log(error);

      this.presentToast(this.translate.instant('TOAST.SAVE_ERROR'));

      //this.router.navigate( ['/salon', this.activatedRoute.snapshot.params.providerId, this.activatedRoute.snapshot.params.shopSlug, 'rezervacija', 'izberi-termin'] );
    });

    /*this.onSubmit.emit( {
      'firstName': this.firstName.value, 
      'lastName': this.lastName.value, 
      'email': this.email.value,
      'password': this.matchingPasswords.controls['password'].value 
    });

    this.authService.registerByEmail( 
      this.firstName.value,
      this.lastName.value,
      this.email.value,
      this.matchingPasswords.controls['password'].value,
      this.returnUrl
    );*/

  }

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => this.returnUrl = params['return']);

    this.name = new FormControl('', [
      Validators.required,
    ]);

    this.phone = new FormControl('', [
      Validators.required,
      //Validators.pattern(' *(040|030|068|031|041|051|071|070|064){1,1} *[0-9]{3,3}[ -]*[0-9]{3,3} *')
      Validators.pattern( ' *[0-9 \-]{8,20} *')
    ]);

    this.instagram = new FormControl('', [
      Validators.pattern(' *https://www.instagram.com/[a-zA-Z0-9._/]{3,} *')
    ]);

    this.school = new FormControl('', []);

    this.experience = new FormControl('', []);

    this.description = new FormControl('', []);

    this.companyName = new FormControl('', [
      Validators.required
    ]);

    this.companyAddress = new FormControl('', [
      Validators.required
    ]);

    this.companyTaxId = new FormControl('', [
      Validators.required,
      //Validators.pattern(' *(SI)? *[0-9]{8,8} *')
      Validators.pattern( ' *(SI|HR)? *([0-9]{8,8}|[0-9]{11,11}) *'),
    ]);

    this.companyVatRegistered = new FormControl(false, [
    ]);

    this.companyRegistrationId = new FormControl('', [
      Validators.required,
      //Validators.pattern(' *[0-9]{10,10} *')
      Validators.pattern( ' *[0-9]{8,11} *')
    ]);

    this.companyBankAccount = new FormControl('', [
      Validators.required,
      //Validators.pattern(' *SI56 ?[0-9]{4,4} *[0-9]{4,4} *[0-9]{4,4} *[0-9]{3,3} *')
      Validators.pattern( ' *[A-Z]{2,2}[ 0-9]{10,30} *')
    ]);


    /*this.email = new FormControl('', [ 
      Validators.required, 
      Validators.pattern( '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}' )
    ]);*/


    this.form = new FormGroup({
      name: this.name,
      phone: this.phone,
      //email: this.email,
      instagram: this.instagram,
      school: this.school,
      experience: this.experience,
      description: this.description,
      companyName: this.companyName,
      companyAddress: this.companyAddress,
      companyTaxId: this.companyTaxId,
      companyVatRegistered: this.companyVatRegistered,
      companyRegistrationId: this.companyRegistrationId,
      companyBankAccount: this.companyBankAccount,
    });

    this.phoneChangesSubscription = this.form.get('phone').valueChanges.pipe(pairwise()).subscribe(([prev, next]: [any, any]) => {

      if (prev === next) {
        return false;
      }

      this.phone.setValue(this.formatter(next));

    });

    this.taxIdChangesSubscription = this.form.get('companyTaxId').valueChanges.pipe(pairwise()).subscribe(([prev, next]: [any, any]) => {

      console.log('change: ' + prev + ' vs. ' + next);

      if (prev && next && prev.replace(/\D/g, "") == next.replace(/\D/g, "")) {
        console.log('same tax id');
        return false;
      }

      if (!this.form.get('companyTaxId').valid) {
        this.companyDataEditing = false;
        return false;
      }

      /**if ( prev.match(/\d+/g).map(Number) == next.match(/\d+/g).map(Number) ) {
        console.log('change: ' + prev + ' vs. ' + next);
      }*/

      this.http.get(environment.apiUrl + '/companies?taxId=' + next).pipe(
        map(response => new Company(response))
      ).subscribe(

        company => {
          console.log(company);
          this.companyName.setValue(company.name);
          this.companyAddress.setValue(company.address);
          this.companyVatRegistered.setValue(company.vatRegistered);
          //this.companyTaxId.setValue(company.taxId);
          this.companyBankAccount.setValue(company.getFormatedBankAccount());
          this.companyRegistrationId.setValue(company.registrationId);
          this.companyDataEditing = true;

          if (!this.companyTaxId.value || this.companyTaxId.value.length < company.taxId.length) {
            this.companyTaxId.setValue(company.taxId);
          }

        },

        err => {
          this.companyName.setValue(null);
          this.companyAddress.setValue(null);
          this.companyVatRegistered.setValue(false);
          this.companyBankAccount.setValue(null);
          this.companyRegistrationId.setValue(null);
          this.companyDataEditing = true;
          this.companyDataEditing = true;
        }

      );


    });

  }

  ngOnDestroy() {
    this.taxIdChangesSubscription.unsubscribe();
  }

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }



  parser(value) {

    if (!value || !value.substr(1)) {
      return '';
    }

    var formattedInt = this.phoneUtils.getInternational(value, 'SI');

    var formattedIntArray = formattedInt.split(" ");

    var intPrefix = formattedIntArray.shift();

    return intPrefix + ' ' + formattedIntArray.join('');

  }

  formatter(value) {

    if (value) {

      var formatted = this.phoneUtils.getNational(value, 'SI');


      if (formatted && formatted.substr(0, 1) !== '0') {
        return '0' + formatted;
      }

      return formatted;

    } else {

      return '';

    }

    /*if ( value ) { 
      return '0' + value.substr(5);
    } else {
      return ''
    }*/
  }

  isPhoneValid() {
    if (this.phone.value) {
      let parsed = parse(this.phone.value, 'SI');
      return !!parsed.phone;
    } else {
      return true;
    }
  }

}
