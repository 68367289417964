<!--<ion-header>
  <ion-toolbar mode="ios">
    <ion-title >Izberi dan</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>

  <ion-button (click)="onSelected( DatePeriodPickedOption.Today )">Danes</ion-button>
  <ion-button (click)="onSelected( DatePeriodPickedOption.Tomorrow )">Jutri</ion-button>
  <ion-button *ngIf="dayOfWeek <= 4" (click)="onSelected( DatePeriodPickedOption.ThisWeek )">Ta teden</ion-button>
  <ion-button *ngIf="dayOfWeek > 4" (click)="onSelected( DatePeriodPickedOption.NextWeek )">Naslednji teden</ion-button>
  <!--<ion-button (click)="onSelected( DatePeriodPickedOption.Whenever )">Kadarkoli</ion-button>-->

  <!--<mat-calendar 
    [startAt]="startAt"
    [minDate]="minDate" 
    [maxDate]="maxDate" 
    (selectedChange)="onSelected('custom', $event)">

  </mat-calendar>-->
   <!-- [selected]="searchPreferences?.datePeriod?.fromMoment?.toDate()"  [dateFilter]-->

<!--</ion-content>-->

<ion-header class="ion-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios" color="primary">
    <ion-title class="header-title ion-text-left">Izberi dan</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding search-modal" color="primary">

  <section class="visit-date">
    <ion-button (click)="onSelected( DatePeriodPickedOption.Today )" color="secondary" class="clickable btn--small">Danes</ion-button>
    <ion-button (click)="onSelected( DatePeriodPickedOption.Tomorrow )" color="secondary" class="clickable btn--small">Jutri</ion-button>
    <ion-button *ngIf="dayOfWeek <= 4" (click)="onSelected( DatePeriodPickedOption.ThisWeek )" color="secondary" class="clickable btn--small">Ta teden</ion-button>
    <ion-button *ngIf="dayOfWeek > 4" (click)="onSelected( DatePeriodPickedOption.NextWeek )" color="secondary" class="clickable btn--small">Naslednji teden</ion-button>
    <!--<ion-button color="secondary" class="btn--small">Kadarkoli</ion-button>-->
  </section>

  <mat-calendar 
    [startAt]="startAt"
    [minDate]="minDate" 
    [maxDate]="maxDate" 
    (selectedChange)="onSelected( DatePeriodPickedOption.Custom, $event )">

  </mat-calendar>

</ion-content>