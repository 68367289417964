<div style="background-color: black; padding-bottom: 50px;">
  <ion-grid fixed class="ion-no-padding">
    <div class="secondary-menu ion-padding">
      <div class="secondary-menu__main">
        <a [routerLink]="['/info', 'pomoc']">{{ 'FOOTER.HELP' | translate }}</a>
        <a [routerLink]="['/info', 'za-lastnike-salonov']">{{ 'FOOTER.FOR_OWNERS' | translate }}</a>
        <a [routerLink]="['/info', 'kontakt']">{{ 'FOOTER.CONTACT' | translate }}</a>
      </div>

      <div class="secondary-menu__social">
        <a src="https://www.facebook.com/book123app">
          <ion-icon class="facebook"></ion-icon>
        </a>
        <a src="https://www.instagram.com/book123app/">
          <ion-icon  class="instagram"></ion-icon>
        </a>
        <a>
          <ion-icon class="twitter"></ion-icon>
        </a>
      </div>

      <div class="secondary-menu__legal">
        <p>
          <a [routerLink]="['/info', 'zasebnost']">{{ 'FOOTER.PRIVACY' | translate }}</a>
        </p>
        <p>
          <a [routerLink]="['/info', 'splosni-pogoji']">{{ 'FOOTER.TERMS' | translate }}</a>
        </p>
      </div>

        <p class="secondary-menu__copyrights">&copy; 2022 <a style="color:#707070; text-decoration: none;" href="https://pricepilot.io">Ludami d.o.o.</a> {{ 'FOOTER.RIGHTS' | translate }} 0.2.0</p>
    </div>
    
  </ion-grid>
</div>
