import { Provider } from './provider.model';

export class ProviderUser {

  public id: string;
  public promotionsViaEmail: boolean
  public promotionsViaSms: boolean
  public provider: Provider

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.promotionsViaEmail = obj && obj.promotionsViaEmail || false;
    this.promotionsViaSms = obj && obj.promotionsViaSms || false;

    if ( obj && obj.provider ) {
      this.provider = ( obj.provider instanceof Provider ) ? obj.provider: new Provider( obj.provider );
    }

  }

}