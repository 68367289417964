import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-provider-modal',
  templateUrl: './provider-modal.component.html',
  styleUrls: ['./provider-modal.component.scss'],
})
export class ProviderModalComponent implements OnInit {


  constructor(private modalCtrl: ModalController ) { }

  ngOnInit() {}



  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }


  onSaved( response ) {
    this.modalCtrl.dismiss( response, 'saved' );
  }

}
