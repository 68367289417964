import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { parse, format, AsYouType } from 'libphonenumber-js';
import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { PPAuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { PreviousRouteService } from './previous-route.service';
import { environment } from '../../../environments/environment';

import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkerOnboardingService {


  name: string;
  phone: string;
  companyTaxId: string;
  companyName: string;
  companyAddress: string;
  companyVatRegistered: boolean;
  companyRegistrationId: string;
  companyBankAccount: string;

  successRedirectUrl: string;

  loading = false;

  constructor(private router: Router, private previousRouteService: PreviousRouteService, public toastController: ToastController,  private phoneUtils: PhoneUtilsService, private http: HttpClient, private authService: PPAuthService) {
    
  }


  private parser( value ) {

    if ( !value  || !value.substr(1) ) {
      return '';
    }

    var formattedInt = this.phoneUtils.getInternational( value, 'SI');

    var formattedIntArray = formattedInt.split(" ");

    var intPrefix = formattedIntArray.shift();

    return intPrefix + ' ' + formattedIntArray.join('');

  }

  navigateToWorkerOnboardingFlow = () => {

    this.router.navigate( ['nov-stilist'], { 
      queryParams: {
        successRedirectUrl: this.router.url
      }
    });

  }

  storePosCertCredentials$( providerId, referenceNumber, password ) {

   console.log(providerId);
   console.log(referenceNumber);
   console.log(password);
   console.log('foo');

   return this.http.patch(
      environment.apiUrl + '/providers/' + providerId, 
      {
        posCertReferenceNumber: referenceNumber,
        posCertPassword: password
      }
   ).pipe(
      switchMap( result => {

        return this.authService.getMe$().pipe(
          tap( user => {
            this.authService.me = user;
            this.authService.addMe2( user );
          })
        );

      })

    );

  }

  addProvider$() {

    this.loading = true;

    const bankAccountWithoutSpaces = (this.companyBankAccount).replace(/\s+/g,'');


    const email = this.authService.meSnapshot.email ?  this.authService.meSnapshot.email: 'info@luda.app';

    let taxId = this.companyTaxId;

    if ( taxId.match(/^[0-9]{8,8}$/) ) {
      taxId = 'SI' + taxId;
    } else if (  taxId.match(/^[0-9]{11,11}$/) ) {
      taxId = 'HR' + taxId;
    }

    let body = {
    
      'brandName': this.name,
      'description': 'test',
      //'slogan': 'stilist',
      'companyName':this.companyName,
      'companyAddress': this.companyAddress,
      'companyCountryCode': 'SI',
      'currency': 'EUR',
      'timezone': 'Europe/Ljubljana',
      'taxId': taxId,
      'vatRegistered': this.companyVatRegistered,
      'registrationId': this.companyRegistrationId,
      'transactionAccount1': bankAccountWithoutSpaces,
      'email': email,
      'phone1':  this.parser( this.phone ),
      //'website': form.get('instagram').value,
      'luda': true
    
    };

    return this.http.post(
      environment.apiUrl + '/providers', 
      body, { observe: 'response' }
    ).pipe(
      switchMap( result => {

        return this.authService.getMe$().pipe(
          tap( user => {
            this.authService.me = user;
            this.authService.addMe2( user );
          })
        );

      })

    );


  }

}
