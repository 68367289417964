import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HoursPeriod } from '../../core/models/search-preferences/hours-period.model';
import { ExecutionTimeRangeCommand } from '../../core/models/executionTimeRangeCommand.model';
import { ExecutionTimeRange } from '../../core/models/executionTimeRange.model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit, OnDestroy {

  @Output() onChange = new EventEmitter<object>();

  mondayHours: HoursPeriod;
  mondayDefaultLower: 8;
  mondayDefaultUpper: 19;

  tuesdayHours: HoursPeriod;
  tuesdayDefaultLower: 8;
  tuesdayDefaultUpper: 19;

  wednesdayHours: HoursPeriod;
  wednesdayDefaultLower: 8;
  wednesdayDefaultUpper: 19;

  thursdayHours: HoursPeriod;
  thursdayDefaultLower: 8;
  thursdayDefaultUpper: 19;

  fridayHours: HoursPeriod;
  fridayDefaultLower: 8;
  fridayDefaultUpper: 19;

  saturdayHours: HoursPeriod;
  sundayHours: HoursPeriod;
  holidayHours: HoursPeriod;

  otherThenMondayChanged = false;

  languageSubscription: Subscription;
  mondayLang: string;
  tuesdayLang: string;
  wednesdayLang: string;
  thursdayLang: string;
  fridayLang: string;
  saturdayLang: string;
  sundayLang: string;
  holidayLang: string;

  constructor(
    private translate: TranslateService
  ) {

    this.mondayHours = new HoursPeriod( 8, 19 );
    this.tuesdayHours = new HoursPeriod( 8, 19 );
    this.wednesdayHours = new HoursPeriod( 8, 19 );
    this.thursdayHours = new HoursPeriod( 8, 19 );
    this.fridayHours = new HoursPeriod( 8, 19 );
    this.saturdayHours = new HoursPeriod( 8, 12 );
    this.sundayHours = null;
    this.holidayHours = null;
  }

  ngOnInit() {
    this.languageSubscription = this.translate.onLangChange.subscribe(s => {
      this.mondayLang = this.translate.instant('SHARED_WORKING_HOURS.MONDAY');
      this.tuesdayLang = this.translate.instant('SHARED_WORKING_HOURS.TUESDAY');
      this.wednesdayLang = this.translate.instant('SHARED_WORKING_HOURS.WEDNESDAY');
      this.thursdayLang = this.translate.instant('SHARED_WORKING_HOURS.THURSDAY');
      this.fridayLang = this.translate.instant('SHARED_WORKING_HOURS.FRIDAY');
      this.saturdayLang = this.translate.instant('SHARED_WORKING_HOURS.SATURDAY');
      this.sundayLang = this.translate.instant('SHARED_WORKING_HOURS.SUNDAY');
      this.holidayLang = this.translate.instant('SHARED_WORKING_HOURS.HOLIDAYS');
    });

    this.onWorkingHoursChanged();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  getExecutionTimeRangeTimeFormat( hp: HoursPeriod ) { 

    console.log(hp);
    console.log('getExecutionTimeRangeTimeFormat');

    if (!hp) {
      return false;
    }


    let from = hp.fromHour + ':00:00';
    let to = hp.toHour + ':00:00';

    return [ from, to ];

  }

  onWorkingHoursChanged() {

    console.log('onWorkingHoursChanged()...');

    let workingHours: ExecutionTimeRange;

    let commands: ExecutionTimeRangeCommand[] = [];

    if ( this.getExecutionTimeRangeTimeFormat( this.mondayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[1],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.mondayHours ) ] } ) );
    }

    if ( this.getExecutionTimeRangeTimeFormat( this.tuesdayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[2],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.tuesdayHours ) ] } ) );
    }

    if ( this.getExecutionTimeRangeTimeFormat( this.wednesdayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[3],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.wednesdayHours ) ] } ) );
    }

    if ( this.getExecutionTimeRangeTimeFormat( this.thursdayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[4],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.thursdayHours ) ] } ) );
    }
    

    if ( this.getExecutionTimeRangeTimeFormat( this.fridayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[5],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.fridayHours ) ] } ) );
    }

    if ( this.getExecutionTimeRangeTimeFormat( this.saturdayHours )) {
      commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[6],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.saturdayHours ) ] } ) );
    }
    
    //commands.push( new ExecutionTimeRangeCommand( { "excepted":false,"validForHolidaysArr":[[1,"except_for_holidays"]],"weeklyDays":[7],"dailyIntervals":[ this.getExecutionTimeRangeTimeFormat( this.sundayHours ) ] } ) );

    workingHours = new ExecutionTimeRange ( {"timeZone":"Europe/Ljubljana", "commands": commands } );


    console.log( workingHours );

    this.onChange.emit( workingHours );

    //this.onChange.emit(null);

  }

  onMondayChange( event ) {
    this.mondayHours = event;
    this.onWorkingHoursChanged();

    console.log('on monday change');
    console.log(event);

  }

  onTuesdayChange( event ) {
    this.tuesdayHours = event;
    this.onWorkingHoursChanged();

    console.log('on tuesday change');
    console.log(event);
  }

  onWednesdayChange( event ) {
    this.wednesdayHours = event;
    this.onWorkingHoursChanged();

    console.log('on wednesday change');
    console.log(event);
  }

  onThursdayChange( event ) {
    this.thursdayHours = event;
    this.onWorkingHoursChanged();

    console.log('on thursday change');
    console.log(event);
  }

  onFridayChange( event ) {
    this.fridayHours = event;
    this.onWorkingHoursChanged();

    console.log('on friday change');
    console.log(event);
  }

  onSaturdayChange( event ) {
    this.saturdayHours = event;
    this.onWorkingHoursChanged();

    console.log('on saturday change');
    console.log(event);
  }

  onSundayChange( event ) {
    this.sundayHours = event;
    this.onWorkingHoursChanged();

    console.log('on sunday change');
    console.log(event);
  }

  onHolidayChange( event ) {
    this.holidayHours = event;
    this.onWorkingHoursChanged();

    console.log('on holiday change');
    console.log(event);
  }


  onOtherThenMondayChange( event ) {

    this.otherThenMondayChanged = true;

  }

}
