import { Pipe, PipeTransform } from '@angular/core';
import { PhoneUtilsService } from 'angular-phone-utils-lib';

@Pipe({
  name: 'ppPhone'
})
export class PpPhonePipe implements PipeTransform {

  constructor(private phoneUtils: PhoneUtilsService) {

  }

  transform(value: string, ...args: unknown[]): unknown {
    
    if (value) {

      var formatted = this.phoneUtils.getNational( value, 'SI');

      if ( formatted && formatted.substr(0,1) !== '0' && formatted.substr(0,2) !== '(0' ) {
        return '0' + formatted;
      }

      return formatted;

    } else {
      
      return '';
    
    }

    return null;
  }

}
